import { Event } from '@/types/event';
import { sendReportEvent } from './instana';
import logger, { logLevels } from './logger';
import yieldToMain from '@/helpers/yieldToMain';

const sendMetricEvent = async (dataReport: Event, typeMessage: string) => {
  sendReportEvent(dataReport);
  await yieldToMain();
  logger({
    level: logLevels.info,
    message: `${dataReport.eventName}: ${typeMessage}`,
    data: dataReport,
  });
  await yieldToMain();
};

export { sendMetricEvent };
