import { sendOnClickGA4, sendOnViewGA4 } from '@/helpers/ga4';

export const onClickTags = (
  adId: string,
) => {
  if (adId === 'suspension') {
    sendOnClickGA4(adId);
    return;
  }
};

export const onAdViewTags = (adId: string) => {
  if (adId === 'suspension') {
    sendOnViewGA4(adId);
    return;
  }
};
