import { getCookieValue, setCookie, deleteCookie } from 'cookies-utils';

class CookieStorage {
  path: string;

  href: string;

  domain: string;

  constructor() {
    this.path = '/';
    this.href = window.location.href;
    this.domain = 'catho.com.br';
    if (this.href.match(/localhost/)) {
      this.domain = 'localhost';
    }
  }

  get(name: string){
    return getCookieValue(name)
  };

  set(name: string, value: string, maxAge: number){
    const cookieOptions = {
      name,
      value,
      maxAge,
      path: this.path,
      domain: this.domain,
    };
    setCookie(cookieOptions);
  };

  setWithPureJS(
    name: string,
    value: string,
    expires: number | string,
    domain?: string,
    path?: string
  ){
    const cookieStructure = `${name}=${value}; expires=${expires}; domain=${
      domain || this.domain
    }; path=${path || this.path}`;
    document.cookie = cookieStructure;
  };

  delete(name: string){
    deleteCookie(name, this.path, this.domain);
  };
}

export default CookieStorage;
