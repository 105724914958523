import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useAppContext } from '@/state/store';
import Image from '@/components/Image/Image';
import Paragraph from '@/components/Paragraph/Paragraph';
import useTargetPlanName from '@/hooks/useTargetPlanName';
import style from './standout.scss';
import React from 'preact/compat';
import { sendOnViewGA4 } from '@/helpers/ga4';
import yieldToMain from '@/helpers/yieldToMain';

interface Top20StandoutModalProps {
  adId?: string;
}

const Top20StandOutModal = ({ adId }: Top20StandoutModalProps) => {
  const [state] = useAppContext();
  const targetPlanName = useTargetPlanName();

  useEffect(() => {
    const initFunctions = async () => {
      sendOnViewGA4(adId);
      await yieldToMain();
    }

    initFunctions();
  }, []);

  const renderDefaultUserStandout = () => (
    <React.Fragment>
      <div className={style.Top20NextStandOutBox}>
        <div className={style.NextText}>
          <b>
            Sua <span>nova</span> posição
          </b>
          <br />
          <small>nesta vaga</small>
        </div>
        <div className={style.NextNumber}>
          <Image src='images/profile2.png' alt="Perfil do usuário" />
          <div>
            <span data-testid="next-rank">{state.rankPosition.next}</span>
            <Image src='images/arrow_up.png' alt="Seta apontando para sua nova posição" />
          </div>
        </div>
        <div className={style.NextDecoration}>
          <Image src='images/star.png' alt="Estrela indicando sua nova posição" />
        </div>
      </div>
      <div className={style.Top20ActualStandOutBox}>
        <div className={style.ActualText}>
          <b>Sua posição agora</b>
          <br />
          <small>nesta vaga</small>
        </div>
        <div className={style.ActualNumber}>
          <Image src='images/profile1.png' alt="Perfil do usuário" />
          <div>
            <span data-testid="current-rank">{state.rankPosition.actual}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div id='apply-ad' className={style.Wrapper}>
      <div className={`${style.DefaultModalContainer}`}>
        {renderDefaultUserStandout()}
        <Paragraph textPadding={12} data-testid="modal-desc">
          Com o {targetPlanName}, <b>seu CV pula</b>{' '}
          <b>diretamente para os 20 primeiros</b> que o RH desta vaga{' '}
          terá acesso e deixe centenas para trás.
        </Paragraph>
      </div>
    </div>
  );
};

export default Top20StandOutModal;