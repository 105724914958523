import { FunctionalComponent, h } from 'preact';
import { useEffect, useContext } from 'preact/hooks';
import { sendMetricEvent } from '@/services/metrics';
import { Context } from '@/state/store';
import EventsName from '@/constants/EVENTS_NAME';
import TimedController from '@/controllers/Timed/TimedController';
import style from '@/components/Styles/AdsFontFamily.scss';
import isMobile from '@/helpers/device';

const LoggedOut: FunctionalComponent = () => {
  const [state] = useContext(Context);

  useEffect(() => {
    if (state.loadedAdId) {
      setTimeout(async () => {
        sendMetricEvent(
          {
            eventName: EventsName.show_ad,
            adId: state.loadedAdId,
            startTime: state.handleTime,
            endTime: state.endTime,
            apply: state.apply,
            rankPosition: state.rankPosition,
            loggedOut: true,
          },
          'loadedAdId'
        );
      }, 0);
    }
  }, [state.loadedAdId]);

  useEffect(() => {
    if (state.acceptedAdId) {
      setTimeout(async () => {
        sendMetricEvent(
          {
            eventName: EventsName.accept_ad,
            adId: state.acceptedAdId,
            startTime: state.loadTime,
            endTime: state.endTime,
            apply: state.apply,
            loggedOut: true,
          },
          'acceptedAdId'
        );

        const targetUrl = `${process.env.PRO_ADVANTAGES_URL}?adId=${state.acceptedAdId}&jobId=${state.jobId}`;
        window.location.href = targetUrl;
      }, 0)
    }
  }, [state.acceptedAdId]);

  useEffect(() => {
    if (state.closedAdId) {
      setTimeout(() => {
        sendMetricEvent(
          {
            eventName: EventsName.close_ad,
            adId: state.closedAdId,
            startTime: state.loadTime,
            endTime: state.endTime,
            apply: state.apply,
            loggedOut: true,
          },
          'closedAdId'
        );
      }, 0);
    }
  }, [state.closedAdId]);

  return (
    <section>
        <article className={`${style.ModalWrapper}`}>
          <TimedController loggedOutDesktop={!isMobile()} />
        </article>
    </section>
  );
};

export default LoggedOut;
