/* eslint-disable no-unused-vars */
import { h, Fragment, VNode } from 'preact';
import { Suspense, createPortal, lazy } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';
import { ADS_POSITION } from '@/constants/ADS_PROPERTIES';
import { useAppContext } from '@/state/store';
import { getRandomHash } from '@/services/hash';
import { onClickTags, onAdViewTags } from './BannerGaEvents';
import { messageProp } from '@/types/message';
import { handleOnProp } from '@/types/handleOn';

const Pro = lazy(() => import(/* webpackChunkName: "ProBanner" */ '../../components/Ads/Banners/Pro/Pro'));
const ScheduledCancellation = lazy(() => import(/* webpackChunkName: "ScheduledCancellationBanner" */ '../../components/Ads/Banners/ScheduledCancellation/Banner'));
const Suspension = lazy(() => import(/* webpackChunkName: "SuspensionBanner" */ '../../components/Ads/Banners/Suspension/Banner'));
const PCDReportNotice = lazy(() => import(/* webpackChunkName: "PCDReportNoticeBanner" */ '../../components/Ads/Banners/PCDReportNotice/PCDReportNotice'));

const BannerController = () => {
  const [, dispatch] = useAppContext();
  const [message, setMessage] = useState<messageProp>(null);
  const [banners, setBanners] = useState<VNode[]>(null);

  const handleOnLoad = ({ adId, adHash, position }: handleOnProp) => {
    const localPosition = position || ADS_POSITION.CENTER;

    dispatch(
      {
        type: 'BANNER_AD_LOADED',
        payload: { adId, adHash, position: localPosition },
      },
      { delay: 10 }
    );

    onAdViewTags(adId);
  };

  const handleOnAccept =
    ({ adId, adHash, position, label, targetUrl }: handleOnProp) =>
    () => {
      const localPosition = position || ADS_POSITION.CENTER;
      dispatch({
        type: 'BANNER_AD_ACCEPTED',
        payload: {
          adId,
          adHash,
          position: localPosition,
          targetUrl:
            typeof window.visitAdvantagePro !== 'undefined'
              ? process.env.PRO_ADVANTAGES_URL
              : targetUrl,
        },
      });

      onClickTags(adId);
    };

  const detectElements = () => {
    const list = [];

    document.querySelectorAll('div[data-banner-id]').forEach((item) => {
      const adHash = getRandomHash(10);
      let banner = null;
      const adId = item.getAttribute('data-banner-id');
      const position = item.getAttribute('data-banner-position');
      const variant = item.getAttribute('data-prop-variant');

      switch (adId) {
        case 'scheduled-cancellation-banner':
          const cancellationDate = item.getAttribute(
            'data-prop-cancellation-date'
          );
          const planTitle = item.getAttribute('data-prop-title-plan');
          banner = (
            <Suspense fallback={<div></div>}>
              <ScheduledCancellation
                adId='scheduled-cancellation'
                cancellationDate={cancellationDate}
                planTitle={planTitle}
                adHash={adHash}
                position={position}
                handleOnLoad={handleOnLoad}
                handleOnAccept={handleOnAccept}
              />
            </Suspense>
          );
          break;
        case 'suspension-banner':
          banner = (
            <Suspense fallback={<div></div>}>
              <Suspension
                adId='suspension'
                adHash={adHash}
                position={position}
                variant={variant}
                handleOnLoad={handleOnLoad}
                handleOnAccept={handleOnAccept}
              />
            </Suspense>
          );
          break;
        case 'pcd-report-notice-banner':
          banner = (
            <Suspense fallback={<div></div>}>
              <PCDReportNotice
                adId='pcd-report-notice'
                adHash={adHash}
                position={position}
                handleOnLoad={handleOnLoad}
                handleOnAccept={handleOnAccept}
              />
            </Suspense>
          );
          break;
        case 'pro-banner':
          banner = (
            <Suspense fallback={<div></div>}>
              <Pro
                adId='pro'
                adHash={adHash}
                position={position}
                handleOnLoad={handleOnLoad}
                handleOnAccept={handleOnAccept}
              />
            </Suspense>
          );
          break;
      }

      list.push(createPortal(banner, item));
    });

    setBanners(list);
  };

  const handleMessage = (message: messageProp) => {
    const { data } = message;
    const { eventName } = data || {};

    if (eventName === 'mktadsapp-banner-started') {
      detectElements();
    }
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      (eventMessage) => {
        setMessage(eventMessage);
      },
      false
    );

    setTimeout(() => {
      detectElements();
    }, 1500);
  }, []);

  useEffect(() => {
    if (message) {
      handleMessage(message);
    }
  }, [message]);

  return <Fragment>{banners}</Fragment>;
};

export default BannerController;
