import { h } from 'preact';
import React from 'preact/compat';

interface IHowItWorksContent {
  targetPlanName?: string;
}

interface IGetDataStepModal {
  variantNumber?: number | string | boolean;
  currentStep?: number;
  isAggregateJobFlowOnboarding?: number | string | boolean
}

export interface IGetDataStepModalSingleReturn {
  title: string;
  imagePath: string;
  imageAlt: string;
  content: ({}: IHowItWorksContent) => JSX.Element;
}

const data = {
  default: {
    steps: [
      {
        title: 'Você criou um cadastro gratuito',
        imagePath: 'images/tour/draw-1.svg',
        imageAlt: 'Ilustração mulher ao lado de um cofrinho em forma de porco',
        content: ({}: IHowItWorksContent) => {
          return (
            <React.Fragment >
              Envie quantos CVs quiser para as{' '}
              <b data-testid="step-1">milhares de vagas disponíveis.</b> E o melhor:{' '}
              <b>totalmente grátis!</b>
            </React.Fragment>
          );
        },
      },
      {
        title: 'Preencha e mantenha seu CV atualizado',
        imagePath: 'images/tour/draw-2.svg',
        imageAlt: 'Ilustração um homem e uma mulher ao lado de um celular preenchendo dados',
        content: ({}: IHowItWorksContent) => {
          return (
            <React.Fragment >
              <b data-testid="step-2">
                Após o cadastro inicial, o preenchimento do CV é o passo mais
                importante.
              </b>{' '}
              É com ele que você vai se candidatar às vagas.
            </React.Fragment>
          );
        },
      },
      {
        title: 'Apareça em destaque para os recrutadores',
        imagePath: 'images/tour/draw-3.svg',
        imageAlt: 'Ilustração você em destaque',
        content: ({ targetPlanName }: IHowItWorksContent) => {
          return (
            <React.Fragment>
              Ganhe destaque entre os candidatos e{' '}
              <b data-testid="step-3">aumente suas chances de receber contatos em até 18 vezes</b>{' '}
              com o {targetPlanName}.
            </React.Fragment>
          );
        },
      },
    ],
  },
  aggregateJob : {
    steps: [
      {
        title: 'Parabéns! Você acaba de se cadastrar na Catho',
        imagePath: 'images/tour/draw-1-v1.svg',
        imageAlt: 'Ilustração mulher utilizando notebook',
        content: ({}: IHowItWorksContent) => {
          return (
            <React.Fragment>
              <b>Candidate-se grátis!</b>
              <p>
                {' '}
                Mas, se você quiser, também temos <b>planos exclusivos</b> que
                podem te <b>ajudar ainda mais</b> na sua busca pelo{' '}
                <b>emprego dos sonhos</b>.
              </p>
            </React.Fragment>
          );
        },
      },
      {
        title: 'Temos todas as vagas em um só lugar',
        imagePath: 'images/tour/draw-2-v1.svg',
        imageAlt: 'Ilustração homem utilizando site',
        content: ({}: IHowItWorksContent) => {
          return (
            <React.Fragment>
              Você não precisa mais buscar vagas de emprego fora da Catho.
              <p>Aqui você encontra as nossas e as de sites externos.</p>{' '}
              <b>Tudo em um mesmo lugar!</b>
            </React.Fragment>
          );
        },
      },
      {
        title: 'Mantenha seu CV sempre atualizado',
        imagePath: 'images/tour/draw-3-v1.svg',
        imageAlt: 'Ilustração um homem e uma mulher visualizando um currículo',
        content: ({}: IHowItWorksContent) => {
          return (
            <React.Fragment>
              <p>
                Isso <b>aumenta suas chances de ser contratado</b> e é com ele
                que você vai se candidatar às vagas.
              </p>
              <b>
                Ahhh... E os recrutadores podem te encontrar pela busca de
                currículos.
              </b>
            </React.Fragment>
          );
        },
      },
    ],
  },
};

const getDataStepModal = ({
  currentStep,
  isAggregateJobFlowOnboarding
}: IGetDataStepModal): IGetDataStepModalSingleReturn => {
  if (isAggregateJobFlowOnboarding) {
    return data.aggregateJob.steps[currentStep];
  }

  return data.default.steps[currentStep];
};

export { getDataStepModal };