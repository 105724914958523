import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useAppContext } from '@/state/store';
import useTargetPlanName from '@/hooks/useTargetPlanName';
import Image from '@/components/Image/Image';
import Paragraph from '@/components/Paragraph/Paragraph';
import style from './standout.scss';
import React from 'preact/compat';
import { sendOnViewGA4 } from '@/helpers/ga4';
import yieldToMain from '@/helpers/yieldToMain';

interface BigLeapStandoutModalProps {
  adId?: string;
}

const BigLeapStandOutModal = ({
  adId,
}: BigLeapStandoutModalProps) => {
  const [state] = useAppContext();
  const targetPlanName = useTargetPlanName();

  useEffect(() => {
    const initFunctions = async () => {
      sendOnViewGA4(adId);
      await yieldToMain();
    }

    initFunctions();
  }, []);

  const renderDefaultUserStandout = () => (
    <React.Fragment>
      <div className={style.BigLeapNextStandOutBox}>
        <div className={style.NextText}>
          <b>Sua nova posição média</b>
          <br />
          <small>assinando o {targetPlanName}</small>
        </div>
        <div className={style.NextNumber}>
          <Image src='images/star.png' alt="Estrela indicando seu novo rank" />
          <div>
            <span data-testid='next-rank'>{state.rankPosition.next}</span>
          </div>
        </div>
      </div>

      <div className={style.BigLeapActualStandOutBox}>
        <div className={style.ActualText}>
          <b>Sua posição média agora</b>
          <br />
          <small>com o cadastro grátis</small>
        </div>
        <div className={style.ActualNumber}>
          <Image src='images/arrow.png' alt="Seta apontando para seu novo rank" />
          <div>
            <span data-testid='current-rank'>{state.rankPosition.actual}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div id='apply-ad' className={style.Wrapper}>
      <div className={`${style.DefaultModalContainer}`}>
        {renderDefaultUserStandout()}
        <Paragraph textPadding={16} data-testid='modal-desc'>
          Com o <b>{targetPlanName}</b>, seu CV <b>passa na frente</b> de
          centenas de outros que estão no plano gratuito <b>nesta vaga</b>.
        </Paragraph>
      </div>
    </div>
  );
};
export default BigLeapStandOutModal;
