interface AdsPosition {
  CENTER: string;
  UPPERRIGHT: string;
  TOP: string;
}

export const ADS_POSITION: AdsPosition = {
  CENTER: 'centro',
  UPPERRIGHT: 'superior-direito',
  TOP: 'topo',
};