/* eslint-disable react/display-name */
import { h, createContext } from 'preact';
import { useContext, useReducer, useState as useAppState } from 'preact/hooks';
// eslint-disable-next-line import/extensions
import { initialState, reducer, IState } from './reducer';

type ContextType = [IState, (action: any, option?: any) => void];

const Context = createContext<ContextType>([initialState, () => null]);

const asyncDispatch = (dispatch) => (action, options) => {
  const { delay } = options || {};
  let caller = () => {
    dispatch(action);
  };

  if (typeof action === 'function') {
    caller = () => {
      action(dispatch);
    };
  }

  if (delay > 0) {
    setTimeout(caller, delay);
  } else {
    caller();
  }
};

const Store = ({ children, initialState }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useReducer<IState, (action: any) => void>(
    reducer,
    initialState
  );

  return (
    <Context.Provider value={[state, asyncDispatch(dispatch)]}>
      {children}
    </Context.Provider>
  );
};

const withStore = (widget) => (props) => {
  const component = h(widget, props);
  return <Store initialState={initialState}>{component}</Store>;
};

const useAppContext = () => useContext(Context);

export { Context, Store, withStore, useAppContext, useAppState };
