import { reportError, sendSimpleInstanaEvent } from "@/services/instana";

const sendElement = (element: string) => {
  try {
    typeof cathoAnalytics !== 'undefined' &&
      cathoAnalytics.flow !== '' &&
      cathoAnalytics.element(element).send();
  } catch (error) {
    sendSimpleInstanaEvent(`[GA4 error]: failed to send ${element} to ga4`, {
      messageError: error.message
    });
  }
};

export function sendGA4ElementEvent(elementName: string, prop?: any) {
  try {
    if (typeof cathoAnalytics !== 'undefined' &&cathoAnalytics.flow !== '') {
      !!prop
        ? cathoAnalytics.element(elementName).send(prop)
        : cathoAnalytics.element(elementName).send()
    }
  } catch (error) {
    reportError(error.message, {
      errorDetail: `[GA4 error]: failed to send ${elementName} to ga4`
    })
  }
}

export function sendOnClickGA4(adId: string, step?: number) {
  const elementId = {
    ourPlans: 'modal-vantagenspro-p2_button',
    top20StandOutModal: 'modal-pos-top20-p2_button',
    bigLeapStandOutModal: 'modal-pos-bigleap-p2_button',
    jobViewedTop20: 'modal-pre-top20-p2_button',
    defaultStandOutModal: 'modal-pos-generico-p2_button',
    suspension: 'modal-retencao-p2_button',
    tour: {
      0: 'modal-onboard1-nextstep_button',
      1: 'modal-onboard2-nextstep_button',
      2: 'modal-onboard3-p2_button',
    },
    strongCandidate: 'modal-autoenvio-p2_button',
    totalCandidates: 'modal-comp-candidatos-p2_button',
    candidatesPosition: 'modal-comp-posicao-p2_button',
    candidateCompatibility: 'modal-comp-compatibilidade-p2_button',
    normal: 'modal-time-p2_button',
    superApply: 'modal-envioturbo-p2_button',
    segmentedOffer: 'modal-vp-reg_button'
  };

  adId === 'tour'
    ? sendElement(elementId[adId][step])
    : sendElement(elementId[adId]);
}

export const sendOnViewGA4 = async (adId: string, step?: number) => {
  const elementId = {
    ourPlans: 'modal-vantagenspro',
    top20StandOutModal: 'modal-pos-top20',
    bigLeapStandOutModal: 'modal-pos-bigleap',
    defaultStandOutModal: 'modal-pos-generico',
    suspension: 'modal-retencao',
    strongCandidate: 'modal-autoenvio',
    totalCandidates: 'modal-comp-candidatos',
    candidatesPosition: 'modal-comp-posicao',
    candidateCompatibility: 'modal-comp-compatibilidade',
    normal: 'modal-time',
    superApply: 'modal-envioturbo',
    jobViewedTop20: 'modal-pre-top20',
    tour: {
      0: 'modal_onboard1',
      1: 'modal_onboard2',
      2: 'modal_onboard3',
    },
  };

  adId === 'tour'
    ? sendElement(elementId[adId][step])
    : sendElement(elementId[adId]);
};
