const commonCtaLabel = (targePlanName: string) => `Quero o ${targePlanName}`;

export const ctaButtonName = {
  totalCandidates: commonCtaLabel,
  candidatesPosition: commonCtaLabel,
  candidateCompatibility: commonCtaLabel,
};

const commonCloseButtonLabel = 'Ok, Entendi';

export const closeButtonName = {
  totalCandidates: commonCloseButtonLabel,
  candidatesPosition: commonCloseButtonLabel,
  candidateCompatibility: commonCloseButtonLabel,
};

export const title = {
  totalCandidates: 'Quantidade de <br />candidatos por vaga',
  candidatesPosition: 'Sua posição na fila de candidaturas',
  candidateCompatibility: 'Combina com você!',
};
