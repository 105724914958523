import { getCookieValue } from 'cookies-utils';
import { getHash } from './hash';
import { reportError } from './instana';
import logger, { logLevels } from './logger';
import { getServerPath } from './http-utils';

const cactk = getCookieValue('cactk') || '';

const getCredentialHeaders = (headers: { Authorization?: string, 'x-origin'?: string, Accept?: string, 'Content-Type'?: string }) => {
  const newHeaders = headers;
  if (cactk && getServerPath().match('testing')) {
    newHeaders.Authorization = `Bearer ${cactk}`;
  }

  const originUrl = window ? window.location.href : ''
  newHeaders['x-origin-url'] = originUrl;

  return newHeaders;
};

const fetchTotalJob = async ({ jobId }: { jobId: string }) =>
  fetch(`${getServerPath()}/api/job-applies/${jobId}`, {
    credentials: 'include',
    headers: getCredentialHeaders({}),
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error: Error) => {
      logger({
        level: logLevels.error,
        message: `Failed to get job applies for JobID ${jobId}`,
        data: error,
      });

      reportError(error);

      return null;
    });

const fetchAvatarURL = async () =>
  fetch(`${getServerPath()}/api/photo/${getHash(cactk)}`, {
    credentials: 'include',
    headers: getCredentialHeaders({}),
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error: Error) => {
      logger({
        level: logLevels.error,
        message: `Failed to get candidate photo`,
        data: error,
      });

      reportError(error);

      return null;
    });

const fetchRankPosition = async ({ jobId, origin }: { jobId: string, origin: string }) =>
  fetch(`${getServerPath()}/api/rank-position/${jobId}/${getHash(cactk)}`, {
    credentials: 'include',
    headers: getCredentialHeaders({
      'x-origin': origin,
    }),
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      logger({
        level: logLevels.error,
        message: `Failed to get rank position for JobID ${jobId}`,
        data: error,
      });

      reportError(error);

      return null;
    });

const fetchBestRankPosition = async ({ jobIdList, origin }: { jobIdList: string[], origin: string }) =>
  fetch(`${getServerPath()}/api/rank-position/best-position`, {
    credentials: 'include',
    headers: getCredentialHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify({
      jobIdList,
      origin,
    }),
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      logger({
        level: logLevels.error,
        message: 'Failed to get best job position',
        data: error,
      });

      reportError(error);

      return null;
    });

export {
  fetchTotalJob,
  fetchAvatarURL,
  fetchRankPosition,
  fetchBestRankPosition,
};
