import { h, Fragment, FunctionComponent } from 'preact';
import PREFETCH_FILES from '../constants/PREFETCH_FILES';
import AssetsPrefetcher from './AssetsPrefetcher';

interface TagsProps {}

const Tags: FunctionComponent<TagsProps> = () => (
  <Fragment>
    <link
      href={`${process.env.ASSETS_PATH}/bundle.${process.env.ASSETS_HASH}.css`}
      rel='stylesheet'
      media='all'
    />
    <AssetsPrefetcher urlList={PREFETCH_FILES} />
  </Fragment>
);

export default Tags;
