import { h } from 'preact';
import isMobile from '../../helpers/device';
import style from './Image.scss';

interface ImageProps {
  id?: string;
  alt?: string;
  src?: string;
  framed?: boolean;
  hideOnMobileRotate?: boolean;
  className?: string;
  notReplaceSvgWithPng?: boolean;
  lazyLoading?: boolean;
  onClick?: () => void;
}

const Image = ({
  id = '',
  alt = '',
  src = '',
  framed = false,
  hideOnMobileRotate,
  className = null,
  notReplaceSvgWithPng = false,
  lazyLoading = true,
  onClick = () => {},
}: ImageProps) => {
  const newSrc =
    isMobile() && !notReplaceSvgWithPng
      ? src.replace('svg', 'png')
      : src;

  if (framed) {
    const classes = ['image', className || ''];
    return (
      <div
        data-testid='image-framed'
        className={`${style.Frame} ${
          hideOnMobileRotate ? style.HideOnMobileRotate : ''
        }`}
      >
        <img
          id={id}
          alt={alt}
          src={`${process.env.ASSETS_PATH}/${newSrc}`}
          className={classes.join(' ')}
          onClick={onClick}
        />
      </div>
    );
  }

  const classes = [
    `image ${hideOnMobileRotate ? style.HideOnMobileRotate : ''}`,
    className || '',
  ];

  const dynamicProps: any = lazyLoading ? { loading: "lazy" } : {};

  return (
    <img
      data-testid='test-image'
      id={id}
      alt={alt}
      {...dynamicProps}
      src={`${process.env.ASSETS_PATH}/${newSrc}`}
      className={classes.join(' ')}
      onClick={onClick}
    />
  );
};

export default Image;
