/* eslint-disable react/no-danger */
import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useAppContext } from '@/state/store';
import useTargetPlanName from '@/hooks/useTargetPlanName';
import style from './Timed.scss';
import React from 'preact/compat';
import { sendOnViewGA4 } from '@/helpers/ga4';
import yieldToMain from '@/helpers/yieldToMain';

interface TimedProps {
  adId: string;
  handleOnAccept: (adId: string, buttonLabel: string) => () => Promise<void>;
  handleOnClose: (adId: string, buttonLabel: string) => () => Promise<void>;
  loggedOutDesktop?: boolean;
}

const Timed = ({ adId, handleOnAccept, handleOnClose, loggedOutDesktop }: TimedProps) => {
  const [, dispatch] = useAppContext();
  const targetPlanName = useTargetPlanName();

  const okButtonText = `Quero o <b>${targetPlanName}</b>`;
  const closeButtonText = 'Agora não';

  useEffect(() => {
    const initFunctions = async () => {
      sendOnViewGA4(adId);
      await yieldToMain();
      dispatch({ type: 'MODAL_AD_LOADED', payload: { adId } });
      await yieldToMain();
    }
    initFunctions();
  }, []);

  const renderDefaulOfferContent = () => {
    return (
      <React.Fragment>
        <div className={style.Title}>
          Quer ter até 18 vezes mais chances de receber um contato?
        </div>
        <div className={style.Arguments} data-testid='plan-desc'>
          Experimente o {targetPlanName} e apareça em destaque para o recrutador
        </div>
        <button
          data-testid='accept-button'
          type='button'
          className={style.Button}
          onClick={handleOnAccept(adId, okButtonText)}
        >
          {typeof okButtonText === 'string' ? (
            <div dangerouslySetInnerHTML={{ __html: okButtonText }} />
          ) : (
            okButtonText
          )}
        </button>
        <div className={style.BottomButton}>
          <button
            type='button'
            data-testid='close-button'
            id='timed-close-btn'
            className={style.CloseButton}
            onClick={handleOnClose(adId, closeButtonText)}
          >
            <u>{closeButtonText}</u>
          </button>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div className={style.Container} data-loggedOut-desktop={loggedOutDesktop && '1'}>
      <div className={style.ResponsiveWrapper}>
        <div className={style.Wrapper}>
          <div className={style.Box}>
            <div className={style.TopButton}>
              <button
                id='closeTimedAd'
                type='button'
                className={style.CloseButton}
                onClick={handleOnClose(adId, 'x')}
              >
                  X
              </button>
            </div>
            {renderDefaulOfferContent()}
          </div>
        </div>
      </div>
    </div>
  );
  };

  export default Timed;