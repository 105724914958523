import { h, Fragment, VNode } from 'preact';
import { Suspense, createPortal, lazy } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';
import { ADS_POSITION } from '@/constants/ADS_PROPERTIES';
import { useAppContext } from '@/state/store';
import { getRandomHash } from '@/services/hash';
import { handleOnProp } from '@/types/handleOn';
import { messageProp } from '@/types/message';
import { firstUserInteractionListener } from '@/helpers/userInteraction';

const StrongCandidate = lazy(() => import(/* webpackChunkName: "StrongCandidateBanner" */  '../../components/Ads/Banners/StrongCandidate/StrongCandidate'));

const JobSearchBannersController = () => {
  const [, dispatch] = useAppContext();
  const [message, setMessage] = useState<messageProp>(null);
  const [banners, setBanners] = useState<VNode[]>(null);


  const isJobSearchMobile = () => {
    const { clientWidth } = document.documentElement;

    return clientWidth < 1024;
  };

  const handleOnLoad = ({ adId, adHash, position }: handleOnProp) => {
    const localPosition = position || ADS_POSITION.CENTER;

    dispatch(
      {
        type: 'BANNER_AD_LOADED',
        payload: { adId, adHash, position: localPosition },
      },
      { delay: 10 }
    );
  };

  const handleOnAccept =
    ({
      adId,
      adHash,
      position,
      noRedirect = false,
      url,
    }: handleOnProp) =>
    () => {
      const localPosition = position || ADS_POSITION.CENTER;

      let targetUrl = url || process.env.PRO_ADVANTAGES_URL;

      if (typeof window.visitAdvantagePro !== 'undefined') {
        targetUrl = process.env.PRO_ADVANTAGES_URL;
      }

      dispatch({
        type: 'BANNER_AD_ACCEPTED',
        payload: {
          adId,
          adHash,
          position: localPosition,
          targetUrl,
          noRedirect,
        },
      });
    };

  const getJobSearchBanner = async () => {
    const defaultReturn = {
      component: StrongCandidate,
      adId: 'StrongCandidate',
    };

    return defaultReturn;
  };

  const detectElements = async () => {
    setBanners(null)
    const list = [];
    const isJobSeachMobileVersion = isJobSearchMobile();

    const JobSearchBanner = await getJobSearchBanner();

    document.querySelectorAll('div[data-banner-id]').forEach((item) => {
      const adHash = getRandomHash(10);
      let banner = null;
      const adId = item.getAttribute('data-banner-id');

      if (
        adId === 'auto-apply-job-filter' ||
        adId === 'auto-apply-job-results'
      ) {
        item.setAttribute(
          'style',
          'display:flex; justify-content: center; width: 100%;'
        );

        let component = null;
        const bnrPosition = isJobSeachMobileVersion
          ? 'centro'
          : 'abaixo-filtro';

        const BannerComponent = JobSearchBanner.component;

        component = (
          <Suspense fallback={<div></div>}>
            <BannerComponent
              adId={JobSearchBanner.adId}
              hostId={adId}
              adHash={adHash}
              position={bnrPosition}
              handleOnLoad={handleOnLoad}
              handleOnAccept={handleOnAccept}
            />
          </Suspense>
        );

        if (adId === 'auto-apply-job-filter' && !isJobSeachMobileVersion) {
          banner = component;
        }

        if (adId === 'auto-apply-job-results' && isJobSeachMobileVersion) {
          banner = component;
        }
      }

      if(banner) {
        list.push(createPortal(banner, item));
      }
    });

    setBanners(list);
  };

  const handleMessage = (message: messageProp) => {
    const { data } = message;
    const { eventName } = data || {};

    if (eventName === 'mktadsapp-banner-started') {
      detectElements();
    }
  };

  const onWindowResize = () => {
    detectElements();
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      (eventMessage) => {
        setMessage(eventMessage);
      },
      false
    );

    window.addEventListener('resize', onWindowResize, false);

    firstUserInteractionListener(detectElements);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  useEffect(() => {
    if (message) {
      handleMessage(message);
    }
  }, [message]);

  return <Fragment>{banners}</Fragment>;
};

export default JobSearchBannersController;
