import { lazy } from 'preact/compat';
const CandidateCompatibility = lazy(() => import(/* webpackChunkName: "CandidateCompatibilityModal" */  './CandidateCompatibility/CandidateCompatibility'));
const CandidatesPosition = lazy(() => import(/* webpackChunkName: "CandidatesPositionModal" */  './CandidatesPosition/CandidatesPosition'));
const TotalCandidates = lazy(() => import(/* webpackChunkName: "TotalCandidatesModal" */  './TotalCandidates/TotalCandidates'));

export default {
  totalCandidates: TotalCandidates,
  candidatesPosition: CandidatesPosition,
  candidateCompatibility: CandidateCompatibility,
};
