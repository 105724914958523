import {ComponentChildren, h } from 'preact';
import style from './SideButtons.scss';

interface SideButtonsProps {
  children: ComponentChildren;
  foward: () => void;
  back: () => void;
  hideBackStep?: boolean;
  hideForwardStep?: boolean;
}

const SideButtons = ({
  children,
  foward,
  back,
  hideBackStep,
  hideForwardStep,
}: SideButtonsProps) => (
  <div className={`${style.Wrapper}`}>
    {!hideBackStep && (
      <div
        data-testid="back-step"
        className={`${style.ButtonWrapper}`}
        onClick={back}
        role='button'
        tabIndex={0}
      >
        <div className={`${style.SideButton} ${style.LeftSideButton}`} />
      </div>
    )}
    <div data-testid ="children-test"className={`${style.ChildrenWrapper}`}>{children}</div>
    {!hideForwardStep && (
      <div data-testid="foward-step" onClick={foward} role='button' tabIndex={-1}>
        <div className={`${style.SideButton} ${style.RightSideButton}`} />
      </div>
    )}
  </div>
);

export default SideButtons;

