import { h } from 'preact';

import Circle from './Circle';
import style from './Circles.scss';

interface CirclesProps {
  modalStep: number;
  totalSteps: number;
}

const Circles = ({ modalStep, totalSteps }: CirclesProps) => {
  const list = [];

  for (let i = 0; i < totalSteps; i += 1) {
    const isActive = modalStep === i;
    list.push(<Circle active={isActive} key={`circle_${i + 1}`} />);
  }

  return <div className={`${style.Circles}`}>{list}</div>;
};

export default Circles;
