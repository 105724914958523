import { DayjsFactory, DayjsType } from "./dayjs";

type TimeDiff = 'after' | 'before' | 'same' | 'notSet';

interface IMarketingPromoControllConstructor {
  startDatePromotion: string
  lastDatePromotion?: string;
  extensionDatePromotion?: string;
  mktPromoIsEnabledEnv?: boolean;
}

export enum MktPromotionTimeStatus {
  notStart = 'notStart',
  start = 'start',
  extension = 'extension',
  lastDate = 'last',
  endDate = 'end',
}

export class MarketingPromoControll {
  _startDatePromotion: DayjsType;
  _lastDatePromotion: DayjsType;
  _extensionDatePromotion: DayjsType;
  _currentDate: DayjsType;
  _mktPromoIsEnabledEnv: boolean;

  constructor({
    startDatePromotion,
    extensionDatePromotion,
    lastDatePromotion,
    mktPromoIsEnabledEnv,
  }: IMarketingPromoControllConstructor) {
    if (!startDatePromotion && mktPromoIsEnabledEnv) {
      throw new Error(
        '[MarketingPromoControll]: startDatePromotion is missing'
      );
    }
    this._startDatePromotion =
      DayjsFactory.make(startDatePromotion).startOf('day');
    this._currentDate = DayjsFactory.make().startOf('day');
    this._mktPromoIsEnabledEnv = !!mktPromoIsEnabledEnv;

    if (lastDatePromotion) {
      this._lastDatePromotion =
        DayjsFactory.make(lastDatePromotion).startOf('day');
    }
    if (extensionDatePromotion) {
      this._extensionDatePromotion = DayjsFactory.make(
        extensionDatePromotion
      ).startOf('day');
    }
  }

  protected checkTimeDiffStartDate(): TimeDiff {
    if (this._currentDate.isSame(this._startDatePromotion)) {
      return 'same';
    }

    if (this._currentDate.isAfter(this._startDatePromotion)) {
      return 'after';
    }

    return 'before';
  }

  protected checkTimeDiffExtensionDate(): TimeDiff {
    if (!this._extensionDatePromotion) return 'notSet';

    if (this._currentDate.isSame(this._extensionDatePromotion)) {
      return 'same';
    }

    if (this._currentDate.isAfter(this._extensionDatePromotion)) {
      return 'after';
    }

    return 'before';
  }

  protected checkTimeDiffLastDate(): TimeDiff {
    if (!this._lastDatePromotion) return 'notSet';

    if (this._currentDate.isSame(this._lastDatePromotion)) {
      return 'same';
    }

    if (this._currentDate.isAfter(this._lastDatePromotion)) {
      return 'after';
    }

    return 'before';
  }

  getPromotionTimeStatus(): MktPromotionTimeStatus {
    if (!this._mktPromoIsEnabledEnv) return MktPromotionTimeStatus.notStart;

    const startDateTimeDiff = this.checkTimeDiffStartDate();
    const extensionDateTimeDiff = this.checkTimeDiffExtensionDate();
    const lastDateTimeDiff = this.checkTimeDiffLastDate();

    switch (true) {
      case startDateTimeDiff === 'before':
        return MktPromotionTimeStatus.notStart;
      case ['same', 'after'].includes(startDateTimeDiff) &&
        ['notSet', 'before'].includes(extensionDateTimeDiff) &&
        ['notSet', 'before'].includes(lastDateTimeDiff):
        return MktPromotionTimeStatus.start;
      case ['same', 'after'].includes(extensionDateTimeDiff) &&
        ['notSet', 'before'].includes(lastDateTimeDiff):
        return MktPromotionTimeStatus.extension;
      case ['same'].includes(lastDateTimeDiff):
        return MktPromotionTimeStatus.lastDate;
      default:
        return MktPromotionTimeStatus.notStart;
    }
  }

  mktPromoIsActive(): boolean {
    const currentStatus = this.getPromotionTimeStatus();
    return [
      MktPromotionTimeStatus.start,
      MktPromotionTimeStatus.extension,
      MktPromotionTimeStatus.lastDate,
    ].includes(currentStatus);
  }
}