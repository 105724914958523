import { h } from 'preact';
import style from './Circle.scss';

interface CircleProps {
  active: boolean;
}

const Circle = ({ active }: CircleProps) => {
  const blueColor = '#1250C4';
  const greyColor = '#E5EDFC';
  const background = active ? blueColor : greyColor;

  return <div className={`${style.Circle}`} style={{ background }} />;
};

export default Circle;
