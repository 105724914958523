import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import CookieStorage from '@/services/cookie-storage';
import { useAppContext } from '@/state/store';
import ModalTour from '@/components/ModalTour/ModalTour';
import { layouts, customTitleComponents, lazyLoadModals } from '@/components/Ads/PlansModals';

import {
  closeButtonName,
  ctaButtonName,
  title,
} from './PlansModalsAdLabels';
import { messageProp } from '@/types/message';
import MarketingPromoControllFactory from '@/factories/marketingPromoControll';
import yieldToMain from '@/helpers/yieldToMain';
import CustomButton from '@/components/ModalTour/Button.scss';
import { Suspense, lazy } from 'preact/compat';

const MarketingPromoCustomTitle = lazy(() => import(/* webpackChunkName: "MarketingPromoCustomTitle" */  '../../components/Ads/PlansModals/MarketingPromoCustomTitle/MarketingPromoCustomTitle'));

interface CreateBlockCookieParams {
  timeInMinutes: number;
  cookieName: string;
}

const mktPromo = MarketingPromoControllFactory.make();
const isMarketingPromo = mktPromo ? mktPromo.mktPromoIsActive() : false;

const PlansModalsController = () => {
  const [,dispatch] = useAppContext();
  const [selectedAdId, setSelectedAdId] = useState<string>(null);
  const [windowVarListener, setWindowVarListener] = useState(null);
  const [offerHasExpiredOverTime, setOfferHasExpiredOverTime] = useState<boolean>(false);
  const allowedEventsName = ['mktadsapp-our-plans', 'mktsadsapp-segmented-offer'];
  const cookieStorage = new CookieStorage();
  const cookieTimeInMinutes = 15;
  const segmentedOfferTimer = null;
  const showSegmentedOffer = false;

  const handleExpiredOffer = () => {
    setOfferHasExpiredOverTime(true);
    handleOnClose(selectedAdId)();
   }

  const handleOnAccept = (adId: string) => async () => {
    window.requestAnimationFrame(async () =>{
      setSelectedAdId(null);
    });
    await yieldToMain();
    let targetUrl: string;

    switch (adId) {
      case 'ourPlans':
        targetUrl = process.env.PRO_ADVANTAGES_URL;
        break;
      case 'segmentedOffer':
        targetUrl = `${process.env.UPGRADE_URL}?cp=bsc`;
        break;
      default:
        targetUrl = process.env.UPGRADE_URL;
        break;
    }

    dispatch({
      type: 'MODAL_AD_ACCEPTED',
      payload: { adId, targetUrl },
    });
    await yieldToMain();
  };

  const handleOnClose = (adId: string) => async () => {
    window.requestAnimationFrame(async () =>{
      setSelectedAdId(null);
    });
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_CLOSED', payload: { adId } });
    await yieldToMain();
  };

  const checkNewUserCookies = () => {
    const hasNewUserCookie =
      cookieStorage.get('CANDIDATE_ORIGIN') === 'app-register-candidate';
    const hasAdsPosLoginCookie = cookieStorage.get('ADS_POS_LOGIN') === '1';

    if (hasNewUserCookie && !hasAdsPosLoginCookie) {
      const maxAge = 525960 * 60; // 1 year to expire
      cookieStorage.set('ADS_POS_LOGIN', '1', maxAge);

      return { newUser: true };
    }

    return { newUser: false };
  };

  const showAd = async (adId: string, handleTime: number) => {
    window.requestAnimationFrame(() => {
      setSelectedAdId(adId);
    });
    await yieldToMain();

    dispatch({
      type: 'MODAL_AD_OPENED',
      payload: {
        adId,
        handleTime,
        modalType: 'plans',
      },
    });
    await yieldToMain();
  };

  const handleMessage = async (event: messageProp) => {
    const { data } = event;
    const { eventName } = data || {};
    const handleTime = Date.now();

    if (eventName === 'mktadsapp-our-plans') {
      showAd('ourPlans', handleTime);
      await yieldToMain();
      return;
    }

    if (eventName === 'mktsadsapp-segmented-offer' && showSegmentedOffer) {
      showAd('segmentedOffer', handleTime);
      await yieldToMain();
      return;
    }
  };

  const checkCookieIsOut = () => {
    const blockModalOurPlans = !!cookieStorage.get('blockModalOurPlans');
    const modalIsOpen = document.getElementById('mkt-ads-modal-plans-modals');

    if (!blockModalOurPlans && !modalIsOpen) {
      handleOnPageLoadModals();
    }
  };

  const antiflickerChangeListener = () => {
    const antiflicker = document.getElementById('antiFlickerLayer');

    const observer = new MutationObserver((mutations) => {
      if (mutations.length >= 1) {
        // eslint-disable-next-line no-use-before-define
        handleOnPageLoadModals();
      }
    });

    observer.observe(antiflicker, {
      attributes: true,
      attributeFilter: ['style'],
    });
  };

  const createBlockCookie = ({
    timeInMinutes,
    cookieName,
  }: CreateBlockCookieParams) => {
    const expirationTime = timeInMinutes * 60; // 15 minutes in seconds
    const currentDate = new Date();
    currentDate.setSeconds(currentDate.getSeconds() + expirationTime);

    cookieStorage.setWithPureJS(cookieName, '1', currentDate.toUTCString());
  };

  const handleOnPageLoadModals = async () => {
    const url = window.location.href;

    const { newUser } = checkNewUserCookies();
    await yieldToMain();
    const blockModalOurPlans = !!cookieStorage.get('blockModalOurPlans');
    await yieldToMain();

    if (url.includes('/area-candidato') && !newUser && !blockModalOurPlans) {
      const antiflicker = document.getElementById('antiFlickerLayer');

      if (antiflicker && antiflicker.style.display !== 'none') {
        antiflickerChangeListener();
        return;
      }

      let VPType = 'VPDefault';
      let event = 'mktadsapp-our-plans';

      if (showSegmentedOffer && !offerHasExpiredOverTime) {
        event = 'mktsadsapp-segmented-offer'
      }

      window.localStorage.setItem('mktLastVpModal', VPType);
      setTimeout(() => {
        handleMessage({ data: { eventName: event } });
        createBlockCookie({
          timeInMinutes: cookieTimeInMinutes,
          cookieName: 'blockModalOurPlans',
        });
      }, 2500);
    }
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      (eventMessage) => {
        if (eventMessage && allowedEventsName.includes(eventMessage?.data?.eventName || '')) {
          handleMessage(eventMessage);
        }
      },
      false
    );

    const initialCalls = async () => {
      handleOnPageLoadModals();
      await yieldToMain();

      if (window.location.href.includes('/area-candidato')) {
        window.setInterval(checkCookieIsOut, 15000);
      }
    };

    initialCalls();
  }, []);

  useEffect(() => {
    if (windowVarListener) {
      setTimeout(() => clearInterval(windowVarListener), 15000);
      setWindowVarListener(null);
    }
  }, [windowVarListener]);

  let layout = null;
  let defaultButton = null;
  let ctaButton = null;
  let hasCloseButton = true;
  let modalTitle = null;
  let closeModal = null;
  let customTitleComponent = null;
  let customFooterInfoComponent = null;
  const tialInfoText = null;

  if (selectedAdId) {
    const Modal = layouts[selectedAdId];

    modalTitle = title[selectedAdId];
    hasCloseButton = showSegmentedOffer && !offerHasExpiredOverTime;
    closeModal = handleOnClose(selectedAdId);

    defaultButton = {
      text: closeButtonName[selectedAdId],
      onClick: handleOnClose(selectedAdId),
      skin: 'linkUnderlined',
      origin: 'PlansModalsController',
    };

    ctaButton = {
      text: ctaButtonName()[
        selectedAdId
      ],
      onClick: handleOnAccept(selectedAdId),
      skin: 'secondary',
      origin: 'PlansModalsController',
      selectedAdId,
      className: showSegmentedOffer && !offerHasExpiredOverTime ? CustomButton.segmentedOffer : null,
    };

    if (lazyLoadModals.includes(selectedAdId)) {
      layout = (
        <Suspense fallback={<div></div>}>
          <Modal adId={selectedAdId} mktPromo={mktPromo} />
        </Suspense>
      );
    } else {
      layout = (
        <Modal adId={selectedAdId} mktPromo={mktPromo} />
      );
    }

    if (customTitleComponents[selectedAdId]) {
      let CustomTitle = customTitleComponents[selectedAdId];
      customTitleComponent = <CustomTitle />;

      if (isMarketingPromo) {
        customTitleComponent = (
          <Suspense fallback={<div></div>}>
            <MarketingPromoCustomTitle mktPromo={mktPromo} />
          </Suspense>
        );
      }
    }
  }

  const isSegmentedOfferTest = showSegmentedOffer && !offerHasExpiredOverTime && selectedAdId === 'segmentedOffer';

  return (
    selectedAdId && (
      <ModalTour
        id='plans-modals'
        showNextButton
        title={modalTitle}
        closeModal={closeModal}
        extraButton={ctaButton}
        hasCloseButton={hasCloseButton}
        defaultButton={defaultButton}
        customTitleComponent={customTitleComponent}
        large
        customFooterInfoComponent={customFooterInfoComponent}
        CTAInfoText={tialInfoText}
        isSegmentedOfferTest={isSegmentedOfferTest}
        xLarge={isSegmentedOfferTest}
        segmentedOfferTimer={segmentedOfferTimer}
        onOfferExpires={handleExpiredOffer}
      >
        {layout}
      </ModalTour>
    )
  );
};

export default PlansModalsController;
