import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useAppContext } from '@/state/store';
import ModalTour from '@/components/ModalTour/ModalTour';
import { layouts } from '@/components/Ads/SuperApplyModals';
import CtaButtonStyle from '@/components/Ads/SuperApplyModals/ButtonStyles/CtaButtonStyle.scss';

import { ctaButtonName } from './ModalsAdLabels';
import { messageProp } from '@/types/message';
import yieldToMain from '@/helpers/yieldToMain';
import { Suspense } from 'preact/compat';

const PlansModalsController = ({ isPaidUser }: { isPaidUser?: string }) => {
  const [, dispatch] = useAppContext();
  const [selectedAdId, setSelectedAdId] = useState<string>(null);
  const [windowVarListener, setWindowVarListener] = useState(null);
  const allowedEventsName = ['mktadsapp-supper-apply-modal'];

  const handleOnAccept = (adId: string) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });

    const targetUrl = process.env.CHECKOUT_SUPER_APPLY_URL;

    dispatch({
      type: 'MODAL_AD_ACCEPTED',
      payload: {
        adId,
        targetUrl,
      },
    });
    await yieldToMain();
  };

  const handleOnClose = (adId: string) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });

    dispatch({ type: 'MODAL_AD_CLOSED', payload: { adId } });
    await yieldToMain();
  };

  const showAd = async (
    adId: string,
    handleTime: number,
    payload: { jobId: string }
  ) => {
    const { jobId } = payload;

    window.requestAnimationFrame(() => {
      setSelectedAdId(adId);
    });
    await yieldToMain();

    dispatch({
      type: 'MODAL_AD_OPENED',
      payload: {
        adId,
        handleTime,
        modalType: 'supper-apply',
        jobId,
      },
    });
    await yieldToMain();
  };

  const handleMessage = async (event: messageProp) => {
    const { data } = event;
    const { eventName, payload } = data || {};
    const newPayload = payload || {};
    const handleTime = Date.now();

    if (eventName === 'mktadsapp-supper-apply-modal') {
      showAd('superApply', handleTime, newPayload);
    }
    await yieldToMain();
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      (eventMessage) => {
        if(eventMessage && allowedEventsName.includes(eventMessage?.data?.eventName || '')) {
          handleMessage(eventMessage);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (windowVarListener) {
      setTimeout(() => clearInterval(windowVarListener), 15000);
      setWindowVarListener(null);
    }
  }, [windowVarListener]);

  let layout = null;
  let ctaButton = null;
  let closeModal = null;

  if (selectedAdId) {
    const Modal = layouts[selectedAdId];

    closeModal = handleOnClose(selectedAdId);

    ctaButton = {
      text: ctaButtonName()[selectedAdId],
      onClick: handleOnAccept(selectedAdId),
      skin: 'secondary',
      origin: 'SuperApply/ModalsController',
      className: CtaButtonStyle.superApplyCtaButton,
      selectedAdId,
    };

    layout = (
      <Suspense fallback={<div></div>}>
        <Modal adId={selectedAdId} isPaidUser={isPaidUser} />
      </Suspense>
    );
  }

  return (
    selectedAdId && (
      <ModalTour
        id='supper-apply-modal'
        showNextButton={false}
        closeModal={closeModal}
        extraButton={ctaButton}
        title=''
        hasCloseButton
        customCloseImgSrc='images/super-apply/icon_close.png'
      >
        {layout}
      </ModalTour>
    )
  );
};

export default PlansModalsController;
