/* eslint-disable no-unused-vars */
import { sendMetricEvent } from '@/services/metrics';
import {
  fetchTotalJob,
  fetchAvatarURL,
  fetchRankPosition,
  fetchBestRankPosition,
} from '../services/api-request';
import { sendSimpleInstanaEvent } from '@/services/instana';
import yieldToMain from '@/helpers/yieldToMain';

interface ApplyAdByRankPosition {
  jobId: string;
  apply: any;
  handleTime: any;
  candidateId?: string;
  // eslint-disable-next-line no-undef
  standoutList: Record<string, string>;
  eventType: string;
  adId?: string;
  rankPosition?: any;
  url?: string;
  type?: string;
  payload?: string;
  jobApplies?: number;
}

interface BestRankPosition {
  jobIdList: string[];
  origin: string;
}

const selectTotalJob =
  ({ jobId }: { jobId: string }) =>
  async (dispatch: any) => {
    let jobApplies = 0;
    return fetchTotalJob({ jobId }).then((data: any) => {
      if (data && data.total >= 50) {
        jobApplies = data.total;
      }

      dispatch({ type: 'JOBAPPLY_TOTAL_FETCHED', payload: { jobApplies } });
    });
  };

const selectAvatarURL = () => async (dispatch: any) => {
  let url = `${process.env.ASSETS_PATH}/images/avatar.svg`;

  return fetchAvatarURL().then((data: any) => {
    if (data) {
      url = data.url;
    }

    dispatch({ type: 'AVATAR_URL_FETCHED', payload: { url } });
  });
};

const selectApplyAd =
  ({ adId, candidateId, jobId, apply, handleTime }: ApplyAdByRankPosition) =>
  async (dispatch: any) => {
    dispatch({
      type: 'APPLY_AD_STARTED',
      payload: {
        candidateId,
        jobId,
        apply,
        handleTime,
      },
    });

    dispatch({
      type: 'APPLY_AD_SELECTED',
      payload: { adId },
    });
  };

const selectApplyAdByRankPosition =
  ({
    jobId,
    apply,
    handleTime,
    standoutList,
    eventType,
  }: ApplyAdByRankPosition) =>
  async (dispatch: any) => {
    const origin =
      eventType === 'mktadsapp-job-viewed'
        ? 'modal-job-viewed'
        : 'modal-pos-apply';

    return fetchRankPosition({ jobId, origin }).then(async (data: any) => {
      const category = data && data.category ? data.category : 'default';
      const adId = standoutList[category] || standoutList.default;

      if (!data || (data && !!data.isFallback)) {
        sendSimpleInstanaEvent('mktAdsModalPosApplyFallback', { origin });
        await yieldToMain();
        sendMetricEvent(
          {
            eventName: 'mktadsapp-show-fallback-modal',
            adId: 'defaultFallback',
            meta: {
              origin,
              isFallback: data.isFallback || false, //server-side fallback
            },
          },
          'mktAdsModalPosApplyFallback'
        );
        await yieldToMain();
      }

      if (eventType === 'mktadsapp-job-viewed') {
        dispatch({
          type: 'JOB_VIEWED_LOADED',
          payload: {
            jobId,
            handleTime,
            jobViewedShow: category === 'top20',
          },
        });
        await yieldToMain();
      }

      if (eventType !== 'mktadsapp-job-viewed' || category === 'top20') {
        dispatch({
          type: 'APPLY_AD_STARTED',
          payload: {
            jobId,
            apply,
            handleTime,
          },
        });
        await yieldToMain();
        dispatch({
          type: 'APPLY_AD_SELECTED',
          payload: { adId, rankPosition: data },
        });
        await yieldToMain();
      }
    });
  };

const selectBestRankPosition =
  ({ jobIdList, origin }: BestRankPosition) =>
  async (dispatch: any) =>
    fetchBestRankPosition({ jobIdList, origin }).then((data: any) => {
      dispatch({
        type: 'BEST_RANK_POSITION_SELECTED',
        payload: { rankPosition: data },
      });
    });

export {
  selectTotalJob,
  selectAvatarURL,
  selectApplyAd,
  selectApplyAdByRankPosition,
  selectBestRankPosition,
};
