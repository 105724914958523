import { getCookieValue } from "cookies-utils";

const getServerPath = () => {
  if (window && window.UseAdsAppAWSUrl) {
    return window.UseAdsAppAWSUrl;
  }
  return process.env.SERVER_PATH;
};

const getCredentialHeaders = (headers?: { Authorization?: string, 'x-origin'?: string, Accept?: string, 'Content-Type'?: string }) => {
  const cactk = getCookieValue('cactk') || '';

  const newHeaders = headers || {};

  if (cactk && !newHeaders.Authorization) {
    newHeaders.Authorization = `Bearer ${cactk}`;
  }

  return newHeaders;
};

export { getServerPath, getCredentialHeaders };
