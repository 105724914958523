import { h, Fragment, ComponentChild, Component } from 'preact';
import style from './ModalTour.scss';
import Header from './Header';
import Body from './Body';
import Button from './Button';
import Circles from './Circles/Circles';
import Loader from '../Loader/Loader';
import { sendOnClickGA4 } from '@/helpers/ga4';
import yieldToMain from '@/helpers/yieldToMain';
const Countdown: any = () => <></>;

interface ModalButton {
  origin?: string;
  className?: string;
  text: string;
  skin?: string;
  onClick: (...args: any[]) => void;
  selectedAdId?: string;
  adId?: string;
}

interface ModalTourProps {
  id?: string;
  closeModal?: (...args: any[]) => void;
  hasCloseButton?: boolean;
  twoLineHeader?: boolean;
  title: string;
  subtitle?: string;
  contentPaddings?: string;
  defaultButton?: ModalButton;
  extraButton?: ModalButton;
  lowerExtraButton?: ModalButton;
  children: ComponentChild;
  totalSteps?: number;
  currentStep?: number;
  showNextButton?: boolean;
  disableButton?: boolean;
  isLoading?: boolean;
  showTitle?: boolean;
  customTitleComponent?: Component;
  large?: boolean;
  xLarge?: boolean;
  customCloseImgSrc?: string;
  dontFireGaTags?: boolean;
  hasDesktopVersion?: boolean;
  isAggregateJobFlowOnboarding?: boolean | string | number;
  isLastStep?: boolean;
  skipStep?: () => void;
  customFooterInfoComponent?: Component;
  CTAInfoText?: string;
  isSegmentedOfferTest?: boolean;
  segmentedOfferTimer?: string;
  onOfferExpires?: () => void;
}

const ModalTour = ({
  id,
  closeModal,
  hasCloseButton = true,
  twoLineHeader = false,
  title,
  subtitle = '',
  contentPaddings = '36',
  defaultButton,
  extraButton,
  lowerExtraButton = null,
  children,
  totalSteps = null,
  currentStep = null,
  showNextButton = true,
  disableButton = false,
  isLoading = false,
  showTitle = true,
  customTitleComponent = null,
  large = false,
  xLarge = false,
  customCloseImgSrc = null,
  dontFireGaTags = false,
  isAggregateJobFlowOnboarding = false,
  isLastStep,
  skipStep,
  hasDesktopVersion,
  customFooterInfoComponent = null,
  CTAInfoText,
  isSegmentedOfferTest = false,
  onOfferExpires,
  segmentedOfferTimer,
}: ModalTourProps) => {
  const wrapperClasses = [style.ModalTour];
  const wrapperButtonsClasses = [style.ButtonsWrapper];

  if (large) {
    wrapperClasses.push(style.ModalTourLarge);
    wrapperButtonsClasses.push(style.ButtonsWrapperLarge);
  }

  if (xLarge) {
    wrapperClasses.push(style.ModalTourXLarge);
    wrapperButtonsClasses.push(style.ButtonsWrapperXLarge);
  }

  if (hasDesktopVersion) {
    wrapperClasses.push(style.ModalTourXlargeInDesktop);
  }

  const renderer = ({ minutes, seconds, completed }) => {
    const formattedMinutes = minutes > 9 ? minutes : `0${minutes}`;
    const formattedSeconds = seconds > 9 ? seconds : `0${seconds}`;

    if (completed) {
      onOfferExpires();
      return <></>;
    }

    return (
      <div className={style.CountDownOffer}>
        Expira em{' '}
        <span>
          {formattedMinutes}:{formattedSeconds}
        </span>
      </div>
    );
  };

  return (
    <div className={wrapperClasses.join(' ')}>
      <article
        className={isLoading && style.DefaultHeight}
        id={`mkt-ads-modal-${id ? id : 'noset'}`}
      >
        {isLoading && (
          <div className={style.Loader}>
            <Loader />
          </div>
        )}

        {customTitleComponent && customTitleComponent}

        {!customTitleComponent && (
          <Header
            closeModal={closeModal}
            hasCloseButton={hasCloseButton}
            twoLineHeader={twoLineHeader}
            customCloseImgSrc={customCloseImgSrc}
            isAggregateJobFlowOnboarding={isAggregateJobFlowOnboarding}
            isLastStep={isLastStep}
            skipStep={skipStep}
          >
            {showTitle && <div dangerouslySetInnerHTML={{ __html: title }} />}

            {subtitle && (
              <Fragment>
                <br />
                <span className={style.SubTile}>{subtitle}</span>
              </Fragment>
            )}
          </Header>
        )}

        <Body padding={contentPaddings}>{children}</Body>

        {totalSteps > 0 && (
          <Circles totalSteps={totalSteps} modalStep={currentStep} />
        )}

        {(isAggregateJobFlowOnboarding && isLastStep && (
          <>
            <Button
              id='extra-button'
              skin={'primary'}
              onClick={async () => {
                defaultButton.onClick();
                await yieldToMain();
              }}
            >
              Ok, entendi
            </Button>
          </>
        )) || (
          <div className={wrapperButtonsClasses.join(' ')}>
            {extraButton && (
              <Button
                id='extra-button'
                data-testid='extra-button'
                skin={extraButton.skin || 'primary'}
                onClick={async () => {
                  if (!dontFireGaTags) {
                    await yieldToMain();
                    sendOnClickGA4(extraButton.selectedAdId);
                    await yieldToMain();
                  }
                  extraButton.onClick();
                  await yieldToMain();
                }}
                className={extraButton.className ? extraButton.className : ''}
              >
                {isSegmentedOfferTest ? (
                  <Countdown
                    date={segmentedOfferTimer}
                    intervalDelay={0}
                    precision={3}
                    zeroPadTime={4}
                    renderer={renderer}
                  />
                ) : (
                  extraButton.text
                )}
              </Button>
            )}

            {!!CTAInfoText && (
              <p
                className={`${style.ctaInfoText} ${style.ctaInfoTextMobile}`}
                dangerouslySetInnerHTML={{ __html: CTAInfoText }}
              />
            )}

            {customFooterInfoComponent && customFooterInfoComponent}

            {showNextButton && defaultButton.text && (
              <div className={style.defaultBtnWrapper}>
                <Button
                  skin={defaultButton.skin}
                  onClick={async () => {
                    defaultButton.onClick();
                    await yieldToMain();
                  }}
                  id={`${id}-ok-button`}
                  disabled={disableButton}
                >
                  {defaultButton.text}
                </Button>
              </div>
            )}

            {lowerExtraButton && (
              <Button
                skin={lowerExtraButton.skin || 'primary'}
                id='lower-extra-button'
                onClick={async () => {
                  if (!dontFireGaTags) {
                    await yieldToMain();
                    sendOnClickGA4(lowerExtraButton.adId, currentStep);
                    await yieldToMain();
                  }

                  lowerExtraButton.onClick();
                  await yieldToMain();
                }}
              >
                {lowerExtraButton.text}
              </Button>
            )}
          </div>
        )}

        {!!CTAInfoText && (
          <p
            className={`${style.ctaInfoText} ${style.ctaInfoTextDesktop}`}
            dangerouslySetInnerHTML={{ __html: CTAInfoText }}
          />
        )}
      </article>
    </div>
  );
};

export default ModalTour;
