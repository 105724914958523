import { h, Fragment, FunctionComponent } from 'preact';
import Tags from '@/config/Tags';

const BodyApps: FunctionComponent = () => (
  <Fragment>
    <Tags />
  </Fragment>
);

export default BodyApps;
