import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useAppContext } from '@/state/store';
import Image from '@/components/Image/Image';
import style from './style.scss';
import { sendOnViewGA4 } from '@/helpers/ga4';
import { MarketingPromoControll } from '@/helpers/marketingPromoControll';
import yieldToMain from '@/helpers/yieldToMain';

interface OurPlansProps {
  adId: string;
  mktPromo?: MarketingPromoControll;
}

const OurPlans = ({ adId, mktPromo }: OurPlansProps) => {
  const [, dispatch] = useAppContext();

  const mktPromoIsActive = mktPromo && mktPromo.mktPromoIsActive();

  let bulletsArguments = [
      '<b>Envio Turbo</b> - passa seu CV para uma fila exclusiva de candidaturas às vagas mais compatíveis;',
      '<b>Autoenvio™️</b> - envia automaticamente seu CV para as vagas mais compatíveis;',
      '<b>Pule na Frente</b> - passa seu CV na frente dos demais candidatos não pagantes;',
      '<b>Destaque</b> - até 18x mais chances de receber contatos;',
      '<b>Sua competividade nesta vaga </b> - Você faz uma leitura das suas chances na vaga (Número de Candidatos, Sua Posição e Compatibilidade).',
    ];

  let subtitle = 'E tenha acesso a vantagens exclusivas:';

  if (mktPromoIsActive) {
    bulletsArguments[4] = '<b>Suas Chances</b> - saiba o número de candidatos nas vagas, sua posição na fila e compatibilidade do seu CV.';
  }


  const showFinalText = !mktPromoIsActive;
  const argumentsAdditionalClasses = mktPromoIsActive ? style.MktPromoArgumentsContainer : '';

  useEffect(() => {
    const initFunctions = async () => {
      dispatch({
        type: 'MODAL_AD_LOADED',
        payload: { adId },
      });
      await yieldToMain();
      sendOnViewGA4(adId);
      await yieldToMain();
    }

    initFunctions();
  }, []);
  return (
    <div
      id='plans-ad'
      className={style.ourPlansContainer}
      data-test-our-plans-modal
    >
      <h3>{subtitle}</h3>
      <div className={`${style.argumentsContainer} ${argumentsAdditionalClasses}`} data-testid='bullets-arguments'>
        {bulletsArguments.map((argument) => (
          <p key={`ba-${argument}`} className={style.bulletArgument}>
            <span>
              <Image src='images/pink-check.png' alt="Icone check" />
            </span>
            <div dangerouslySetInnerHTML={{ __html: argument }} />
          </p>
        ))}
      </div>
      {showFinalText && <h3>e muitos mais!</h3>}
    </div>
  );
};

export default OurPlans;
