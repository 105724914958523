/* eslint-disable react/no-danger */
import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useAppContext } from '@/state/store';
import CookieStorage from '@/services/cookie-storage';
import yieldToMain from '@/helpers/yieldToMain';
// import { Suspense, lazy } from 'preact/compat';

// const Tour = lazy(() => import(/* webpackChunkName: "TourModal" */  '../../components/Ads/TourModal/Tour'));
import Tour from '../../components/Ads/TourModal/Tour';

type TypeTourController = {
  isAggregateJobFlowOnboarding?: boolean | number | string;
}

const TourController = ({ isAggregateJobFlowOnboarding }: TypeTourController) => {
  const [state, dispatch] = useAppContext();
  const [selectedAdId, setSelectedAdId] = useState<string>(null);

  const cookieStorage = new CookieStorage();

  const handleOnAccept = (adId: string) => async () => {
    window.requestAnimationFrame(async () => setSelectedAdId(null));
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_ACCEPTED', payload: { adId } });
    await yieldToMain();
  };

  const handleOnClose = (adId: string) => async () => {
    window.requestAnimationFrame(async () => setSelectedAdId(null));
    await yieldToMain();
    cookieStorage.delete('mktadsapp-tour');
    dispatch({ type: 'MODAL_AD_CLOSED', payload: { adId } });
    await yieldToMain();
  };

  const showTourAd = async () => {
    const dateNow = Date.now();
    const adId = 'tour';

    if (state.originUrl.match(/apply=true/)) {
      return;
    }

    if (cookieStorage.get('mktadsapp-tour') === 'onboarding') {
      window.requestAnimationFrame(() => {
        setSelectedAdId(adId);
      });
      await yieldToMain();

      dispatch({
        type: 'MODAL_AD_OPENED',
        payload: {
          adId,
          handleTime: dateNow,
          modalType: 'tour',
        },
      });
      await yieldToMain();
    }
  };

  useEffect(() => {
    showTourAd();
  }, []);

  let modal = <Fragment />;

  if (selectedAdId) {
    modal = (
        <Tour
          adId={selectedAdId}
          handleOnAccept={handleOnAccept}
          handleOnClose={handleOnClose}
          isAggregateJobFlowOnboarding={isAggregateJobFlowOnboarding}
        />
    );
  }

  return modal;
};

export default TourController;
