/* eslint-disable import/extensions */
/* eslint-disable react/no-danger */
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useAppContext } from '@/state/store';
import ModalTour from '@/components/ModalTour/ModalTour';
import Modals from '@/components/Ads/CompatibilityModals';

import useTargetPlanName from '@/hooks/useTargetPlanName';

import {
  closeButtonName,
  ctaButtonName,
  title,
} from './CompatibilityModalsAdLabels';
import { messageProp } from '@/types/message';
import yieldToMain from '@/helpers/yieldToMain';
import { Suspense } from 'preact/compat';

const CompatibilityModalsController = () => {
  const [, dispatch] = useAppContext();
  const [selectedAdId, setSelectedAdId] = useState<string>(null);
  const [hideCTA, setHideCTA] = useState(false);
  const [dontFireGaTags, setDontFireGaTags] = useState(false);
  const targetPlanName = useTargetPlanName();
  const allowedEventsName = ['mktadsapp-total-candidates', 'mktadsapp-candidate-position', 'mktadsapp-candidate-compatibility'];

  const handleOnAccept = (adId: string) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_ACCEPTED', payload: { adId } });
    await  yieldToMain();
  };

  const handleOnClose = (adId: string) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_CLOSED', payload: { adId } });
    await  yieldToMain();
  };

  const showAd = async (adId: string, handleTime: number) => {
    window.requestAnimationFrame(() => {
      setSelectedAdId(adId);
    })
    await yieldToMain();

    dispatch({
      type: 'MODAL_AD_OPENED',
      payload: {
        adId,
        handleTime,
        modalType: 'autoSend',
      },
    });
    await yieldToMain()
  };

  const handleMessage = async (event: messageProp) => {
    const { data } = event;
    const { eventName, payload } = data || {};
    const handleTime = Date.now();

    setHideCTA(payload ? payload.hideCTA : false);
    await yieldToMain();
    setDontFireGaTags(payload ? payload.dontFireGaTags : false);
    await yieldToMain();

    switch (eventName) {
      case 'mktadsapp-total-candidates':
        showAd('totalCandidates', handleTime);
        break;
      case 'mktadsapp-candidate-position':
        showAd('candidatesPosition', handleTime);
        break;
      case 'mktadsapp-candidate-compatibility':
        showAd('candidateCompatibility', handleTime);
        break;
    }
    await yieldToMain();
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      (eventMessage) => {
        if(eventMessage && allowedEventsName.includes(eventMessage?.data?.eventName || '')) {
          handleMessage(eventMessage);
        }
      },
      false
    );
  }, []);

  let layout = null;
  let defaultButton = null;
  let extraButton = null;
  const hasCloseButton = true;
  let modalTitle = null;
  let closeModal = null;

  if (selectedAdId) {
    const Modal = Modals[selectedAdId];

    modalTitle = title[selectedAdId];
    const onCloseFunction = handleOnClose(selectedAdId);

    closeModal = () => {
      onCloseFunction();
    };

    const defaultBtnGaLabel = 'ok-entendi';

    defaultButton = {
      text: closeButtonName[selectedAdId],
      onClick: handleOnClose(selectedAdId),
      skin: hideCTA ? 'secondaryOutlined' : 'primaryOutlined',
      origin: 'CompatibilityModalsController',
    };

    if (!hideCTA) {
      const extraButtonLabel = ctaButtonName[selectedAdId](targetPlanName);

      const extraButtonGALabel = 'destaque-extra';

      extraButton = {
        text: extraButtonLabel,
        onClick: handleOnAccept(selectedAdId),
        skin: 'secondary',
        origin: 'CompatibilityModalsController',
        selectedAdId,
      };
    }
    layout = (
      <Suspense fallback={<div></div>}>
        <Modal adId={selectedAdId} dontFireGaTags={dontFireGaTags} />
      </Suspense>
    );
  }

  return (
    selectedAdId && (
      <ModalTour
        id='compatibility-modal'
        showNextButton
        title={modalTitle}
        closeModal={closeModal}
        extraButton={extraButton}
        hasCloseButton={hasCloseButton}
        defaultButton={defaultButton}
        customCloseImgSrc='images/ic_clear.svg'
        dontFireGaTags={dontFireGaTags}
      >
        {layout}
      </ModalTour>
    )
  );
};

export default CompatibilityModalsController;
