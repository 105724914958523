/* eslint-disable react/no-danger */
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useAppContext } from '@/state/store';
import ModalTour from '@/components/ModalTour/ModalTour';
import Modals from '@/components/Ads/AutoSendModals';

import {
  closeButtonName,
  ctaButtonName,
  title,
} from './AutoSendModalsAdLabels';
import { messageProp } from '@/types/message';
import yieldToMain from '@/helpers/yieldToMain';
import { Suspense } from 'preact/compat';

const AutoSendModalsController = () => {
  const [, dispatch] = useAppContext();
  const [selectedAdId, setSelectedAdId] = useState<string>(null);
  const allowedEventsName = ['mktadsapp-explain-auto-apply', 'mktadsapp-sale-auto-apply'];

  const handleOnAccept = (adId: string) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_ACCEPTED', payload: { adId } });
    await yieldToMain();
  };

  const handleOnClose = (adId: string) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_CLOSED', payload: { adId } });
    await  yieldToMain();
  };

  const showAd = async (adId: string, handleTime: number) => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(adId);
      await yieldToMain();
    });
    await yieldToMain();

    dispatch({
      type: 'MODAL_AD_OPENED',
      payload: {
        adId,
        handleTime,
        modalType: 'autoSend',
      },
    });
    await yieldToMain();
  };


  const handleMessage = async (event: messageProp) => {
    const { data } = event;
    const { eventName } = data || {};
    const handleTime = Date.now();

    if (eventName === 'mktadsapp-explain-auto-apply') {
      showAd('strongCandidate', handleTime);
      await yieldToMain();
    }

    if (eventName === 'mktadsapp-sale-auto-apply') {
      showAd('strongCandidate', handleTime);
      await yieldToMain();
    }
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      (eventMessage) => {
        if(eventMessage && allowedEventsName.includes(eventMessage?.data?.eventName || '')) {
          handleMessage(eventMessage);
        }
      },
      false
    );
  }, []);

  let layout = null;
  let defaultButton = null;
  let extraButton = null;
  let hasCloseButton = true;
  let modalTitle = null;
  let closeModal = null;

  if (selectedAdId) {
    const Modal = Modals[selectedAdId];

    modalTitle = title[selectedAdId];
    hasCloseButton = false;
    closeModal = handleOnClose(selectedAdId);

    defaultButton = {
      text: closeButtonName[selectedAdId],
      onClick: handleOnClose(selectedAdId),
      skin: 'link',
      origin: 'AutoSendModalsController',
    };

    extraButton = {
      text: ctaButtonName[selectedAdId],
      onClick: handleOnAccept(selectedAdId),
      skin: 'primary',
      origin: 'AutoSendModalsController',
      selectedAdId,
    };

    layout = (
      <Suspense fallback={<div></div>}>
        <Modal adId={selectedAdId} />
      </Suspense>
    );
  }

  return (
    selectedAdId && (
      <ModalTour
        id='auto-send'
        showNextButton
        title={modalTitle}
        closeModal={closeModal}
        extraButton={extraButton}
        hasCloseButton={hasCloseButton}
        defaultButton={defaultButton}
      >
        {layout}
      </ModalTour>
    )
  );
};

export default AutoSendModalsController;
