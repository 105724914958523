import { lazy } from 'preact/compat';
import OurPlans from './OurPlans/OurPlans';
import OurPlansCustomTitle from './OurPlansCustomTitle/OurPlansCustomTitle';
const SegmentedOffer = lazy(() => import(/* webpackChunkName: "SegmentedOfferModal" */  './SegmentedOffer/SegmentedOffer'));

export const lazyLoadModals =[
  'segmentedOffer'
];

export const layouts = {
  ourPlans: OurPlans,
  segmentedOffer: SegmentedOffer,
};

export const customTitleComponents = {
  ourPlans: OurPlansCustomTitle,
};
