import { h } from 'preact';
import styles from './Loader.scss';

const Loader = () => (
  <div 
    data-testid='test-loader-div'
    className={styles.Loader}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loader;
