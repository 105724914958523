import { h, ComponentChild } from 'preact';
import style from './ModalTour.scss';

interface ModalTourBodyProps {
  children: ComponentChild;
  padding: string;
}

const ModalTourBody = ({ children, padding }: ModalTourBodyProps) => (
  <div
    className={style.Body}
    style={{ padding: `${Number(padding) / 2}px 0;` }}
  >
    <div>
      <div className='responsive-container'>{children}</div>
    </div>
  </div>
);

export default ModalTourBody;
