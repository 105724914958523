import { h, Fragment, FunctionComponent } from 'preact';
import { useEffect, useContext } from 'preact/hooks';
import { sendMetricEvent } from '@/services/metrics';
import { Context } from '@/state/store';
import BannerController from '@/controllers/Banners/BannerController';
import EventsName from '@/constants/EVENTS_NAME';
import JobSearchBannersController from '@/controllers/JobSearchBanners/JobSearchBannersController';

interface BannerType {
  isPaidUser: String;
  eventName: string;
  adId: string;
  position: string;
  startTime: number;
  endTime: number;
  apply: boolean;
}

const Banner: FunctionComponent<BannerType> = ({ isPaidUser }) => {
  const [state] = useContext(Context);

  useEffect(() => {
    if (state.loadedAdHash) {
      const banner = state.loadedBanners[state.loadedAdHash];
      const { adId, position, startTime, endTime } = banner;

      sendMetricEvent(
        {
          eventName: EventsName.show_ad,
          adId,
          position,
          startTime,
          endTime,
          apply: state.apply,
        },
        'loadedAdHash'
      );
    }
  }, [state.loadedAdHash]);

  useEffect(() => {
    if (state.acceptedAdHash) {
      const banner = state.acceptedBanners[state.acceptedAdHash];
      const { adId, position, startTime, endTime } = banner;

      sendMetricEvent(
        {
          eventName: EventsName.accept_ad,
          adId,
          position,
          startTime,
          endTime,
          apply: state.apply,
        },
        'acceptedAdHash'
      );

      if (state.noRedirect) {
        return;
      }

      if (state.targetUrl.includes('?')) {
        window.location.href = `${state.targetUrl}&adId=${adId}`;
        return;
      }

      window.location.href = `${state.targetUrl}?adId=${adId}`;
    }
  }, [state.acceptedAdHash]);

  return (
    <Fragment>
      {isPaidUser !== '1' && (
        <div>
          <BannerController />
          <JobSearchBannersController />
        </div>
      )}
    </Fragment>
  );
};

export default Banner;
