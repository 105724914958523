const firstUserInteractionListener = (callback: () => void) => {
  const eventsList = ['mousemove', 'click', 'scroll', 'touchmove', 'touchstart', 'keydown'];

  function manageEventsListener() {
    callback();

    eventsList.forEach(event => {
      window.removeEventListener(event, manageEventsListener);
    })
  }

  eventsList.forEach((event) => {
    window.addEventListener(event, manageEventsListener);
  });
};

export {
  firstUserInteractionListener
}