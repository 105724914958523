import { h } from 'preact';
import useTargetPlanName from '@/hooks/useTargetPlanName';
import Image from '@/components/Image/Image';
import Paragraph from '@/components/Paragraph/Paragraph';
import SideButtons from '@/components/SideButtons/SideButtons';
import style from './tour.scss';
import { IGetDataStepModalSingleReturn } from './dataHowItWorks';

interface HowItWorksAdProps {
  modalStep: number;
  nextStep: () => void;
  backStep: () => void;
  dataStepModal?: IGetDataStepModalSingleReturn;
}

const HowItWorksAd = ({
  modalStep,
  nextStep,
  backStep,
  dataStepModal,
}: HowItWorksAdProps) => {
  const targetPlanName = useTargetPlanName();

  return (
    <div id='free-trial-faq' style={{ width: '100%', height: '100%' }}>
      <div className={style.DefaultModalContainer}>
        <div style={{ display: modalStep === 0 ? 'block' : 'none' }}>
          <SideButtons data-testid="step-1-buttons" foward={nextStep} back={backStep} hideBackStep>
            <Image src={dataStepModal?.imagePath} alt={dataStepModal?.imageAlt} />
          </SideButtons>
          <Paragraph id='modal-step-1' style={{ padding: '12px 10px 0 10px' }}>
            {dataStepModal?.content({})}
          </Paragraph>
        </div>
        <div style={{ display: modalStep === 1 ? 'block' : 'none' }}>
          <SideButtons data-testid="step-2-buttons" foward={nextStep} back={backStep}>
            <Image src={dataStepModal?.imagePath} alt={dataStepModal?.imageAlt} />
          </SideButtons>
          <Paragraph id='modal-step-2' style={{ padding: '12px 10px 0 10px' }}>
            {dataStepModal?.content({})}
          </Paragraph>
        </div>
        <div style={{ display: modalStep === 2 ? 'block' : 'none' }}>
          <SideButtons data-testid="step-3-buttons" foward={nextStep} back={backStep} hideForwardStep>
            <Image src={dataStepModal?.imagePath} alt={dataStepModal?.imageAlt} />
          </SideButtons>
          <Paragraph id='modal-step-3' style={{ padding: '12px 10px 0 10px' }}>
            {dataStepModal?.content({ targetPlanName })}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksAd;
