import { h } from 'preact';
import style from './style.scss';

const OurPlansCustomTitle = () => {
  const title = 'Escolha a Catho';
  const plansFrase = 'Planos a partir de';
  const pricePlan = '<b>R$ 29,90</b>/mês';
  return (
    <div id='c-title' className={style.CustomTitleContainer}>
      <div className={style.title}>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className={style.divider} />
      <div className={style.price}>
        <p>{plansFrase}</p>
        <span dangerouslySetInnerHTML={{ __html: pricePlan }} />
      </div>
    </div>
  );
};

export default OurPlansCustomTitle;
