/* eslint-disable react/no-danger */
import { ComponentChild, h } from 'preact';
import style from './Button.scss';

interface ButtonProps {
  children: ComponentChild;
  onClick: () => void;
  disabled?: boolean;
  skin: string;
  id: string;
  className?: string;
}

const Button = ({
  children,
  onClick,
  disabled,
  skin,
  id,
  className,

}: ButtonProps) => {
  const classes = [style[skin], className || ''];

  return (
    <div className={`${style.Button}`}>
      <button
        id={id}
        className={classes.join(' ')}
        onClick={disabled ? () => {} : onClick}
        type='button'
      >
        {typeof children === 'string' ? (
          <div dangerouslySetInnerHTML={{ __html: children }} />
        ) : (
          children
        )}
      </button>
    </div>
  );
};

export default Button;
