export const ctaButtonName = {
  strongCandidate: 'Ativar o Autoenvio',
};

export const closeButtonName = {
  strongCandidate: 'Agora não',
};

export const title = {
  strongCandidate:
    'Acerte a vaga dos seus sonhos com o Autoenvio',
};
