import { Event } from '@/types/event';

const reportEvent = ({
  eventName,
  adId,
  duration = 0,
  hash,
  apply,
  rankPosition,
  loggedOut,
}: Event) => {
  if (window.ineum) {
    window.ineum('meta', 'mktadsapp-hash', hash);
    window.ineum('meta', 'mktadsapp-adId', adId);

    window.ineum('reportEvent', eventName, {
      duration,
      meta: {
        adId,
        href: document.location.href,
        apply,
        rankPosition,
        loggedOut,
      },
    });
  }
};

const reportError = (error: Error, meta?: any) => {
  if (window.ineum) {

    if(meta) {
      window.ineum('reportError', error.message || error, { meta });
    } else {
      window.ineum('reportError', error.message || error);
    }
  }
};

const sendReportEvent = ({
  eventName,
  adId,
  startTime,
  endTime,
  apply,
  rankPosition,
  position,
  loggedOut,
}: Event) => {
  if (adId) {
    const duration = endTime - startTime;

    reportEvent({
      eventName,
      adId,
      duration,
      hash: process.env.ASSETS_HASH,
      apply,
      rankPosition,
      position,
      loggedOut,
    });
  }
};

const sendSimpleInstanaEvent = async (event: string, meta: object = {}) => {
  const canSendEvent = window.ineum && process.env.APPLICATION_ENV === 'production';

  if (canSendEvent) {
    window.ineum('reportEvent', event, { meta });
  }
}

export { reportEvent, reportError, sendReportEvent, sendSimpleInstanaEvent };
