import { UserPlan } from '@/types/user';

function useTargetPlanName(planCodeStr?: string): string {
  const planCode = planCodeStr || UserPlan.extra_plan;

  const planNameOptions = {
    [UserPlan.basic_plan]: 'Básico',
    [UserPlan.pro_plan]: 'Profissional',
    [UserPlan.extra_plan]: 'Destaque Extra',
  }

  const targetPlanName = planNameOptions[planCode] || ''
  return targetPlanName;
}

export default useTargetPlanName;