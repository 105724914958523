/* eslint-disable import/newline-after-import */
/* eslint-disable import/first */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
// eslint-disable-next-line import/no-extraneous-dependencies
// embedded polyfills
const poly = require('preact-cli/src/lib/webpack/polyfills');
import habitat from 'preact-habitat';
import Banner from './aggregators/Banner';
import BodyApps from './aggregators/BodyApps';
import Checkout from './aggregators/Modal/Checkout';
import Modal from './aggregators/Modal/Modal';
import LoggedOut from './aggregators/Modal/LoggedOut';
import { withStore } from './state/store';
import './style/global.scss';

//
const bodyHabitat = habitat(withStore(BodyApps));
bodyHabitat.render({
  selector: 'body',
  clean: false,
  defaultProps: {
    environment: process.env.APPLICATION_ENV,
  },
});

const checkoutWidgetHabitat = habitat(withStore(Checkout));
checkoutWidgetHabitat.render({
  selector: '[data-widget-host="mkt-ads-checkout"]',
  clean: true,
});

const modalWidgetHabitat = habitat(withStore(Modal));
modalWidgetHabitat.render({
  selector: '[data-widget-host="mkt-ads-modal"]',
  clean: true,
});

const loggedoutWidgetHabitat = habitat(withStore(LoggedOut));
loggedoutWidgetHabitat.render({
  selector: '[data-widget-host="mkt-ads-loggedout"]',
  clean: true,
});

const bannerWidgetHabitat = habitat(withStore(Banner));
bannerWidgetHabitat.render({
  selector: '[data-widget-host="mkt-ads-banner"]',
  clean: true,
});
