import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import ModalTour from '@/components/ModalTour/ModalTour';
import { useAppContext } from '@/state/store';
import {
  onClickTags,
} from '@/controllers/Tour/TourGaEvents';
import HowItWorksAd from './HowItWorks';
import { getDataStepModal } from './dataHowItWorks';
import { sendOnViewGA4 } from '@/helpers/ga4';

interface TourProps {
  adId: string;
  initialStep?: number;
  isAggregateJobFlowOnboarding?: boolean | string | number;
  handleOnAccept: (adId: string) => () => void;
  handleOnClose: (adId: string) => () => void;
}

const Tour = ({
  adId,
  initialStep,
  handleOnAccept,
  handleOnClose,
  isAggregateJobFlowOnboarding,
}: TourProps) => {
  const MODAL_TOTAL_STEPS = 3;
  const [step, setStep] = useState<number>(initialStep);
  const [isLastStep, setIsLastStep] = useState<boolean>(
    step === MODAL_TOTAL_STEPS - 1
  );
  const [, dispatch] = useAppContext();

  const isValidStep = (currentStep) =>
    !Number.isNaN(currentStep) &&
    currentStep < MODAL_TOTAL_STEPS &&
    currentStep > 0;

  useEffect(() => {
    dispatch({ type: 'MODAL_AD_LOADED', payload: { adId } });
  }, [0]);

  useEffect(() => {
    if (!isValidStep(step)) {
      setStep(0);
    }
  }, [step]);

  useEffect(() => {
    if (step != undefined) {
      if (!isAggregateJobFlowOnboarding){
        sendOnViewGA4(adId, step);
      }
    }

    if (step === MODAL_TOTAL_STEPS - 1) {
      setIsLastStep(true);
      return;
    }

    setIsLastStep(false);
  }, [step]);

  const nextModalStep = (nextStep: number) => {
    if (nextStep >= MODAL_TOTAL_STEPS - 1) {
      handleOnClose(adId)();
      return;
    }

    setStep((currentStep) => currentStep + 1);
  };

  const previousModalStep = (previousStep: number) => {
    if (previousStep < 1) {
      return;
    }

    setStep((currentStep) => currentStep - 1);
  };

  const skipStep = () => {
    handleOnClose(adId)();
  };

  const arrowNextStep = (nextStep: number) => {
    const currentStep = step;
    nextModalStep(nextStep);
    onClickTags(currentStep, 'seta-direita');
  };

  const arrowPreviousStep = (previousStep: number) => {
    const currentStep = step;
    previousModalStep(previousStep);
    onClickTags(currentStep, 'seta-esquerda');
  };

  const extraButtonText = 'Quero <b>me destacar</b>';
  const extraButton = {
    text: extraButtonText,
    onClick: handleOnAccept(adId),
    skin: 'primary',
    adId,
  };

  const okButtonText = isLastStep ? 'Agora não' : 'Continuar \u00bb';
  const defaultButton = {
    text: okButtonText,
    onClick: () => nextModalStep(step),
    skin: isLastStep ? 'link' : 'primary',
    adId,
  };

  const dataStepModal = getDataStepModal({
    currentStep: step,
    isAggregateJobFlowOnboarding,
  });

  return (
    <ModalTour
      id='tour-ad-modal'
      title={dataStepModal?.title}
      closeModal={handleOnClose(adId)}
      lowerExtraButton={isLastStep ? extraButton : null}
      defaultButton={defaultButton}
      currentStep={step}
      totalSteps={MODAL_TOTAL_STEPS}
      hasCloseButton={false}
      isAggregateJobFlowOnboarding={isAggregateJobFlowOnboarding}
      skipStep={skipStep}
      isLastStep={isLastStep}
    >
      <HowItWorksAd
        modalStep={step}
        nextStep={() => arrowNextStep(step)}
        backStep={() => arrowPreviousStep(step)}
        dataStepModal={dataStepModal}
      />
    </ModalTour>
  );
};

export default Tour;
