import dayjs, { Dayjs } from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/pt-br';

dayjs.extend(utc);
dayjs.extend(timezone);

export class DayjsFactory {
  static make(date?: string | number | dayjs.Dayjs | Date): Dayjs {
    if(date) return dayjs(date).tz('America/Sao_paulo').locale('pt-br');

    return dayjs().tz('America/Sao_paulo').locale('pt-br');
  }

  static makeWithoutTZ(date?: string | number | dayjs.Dayjs | Date): Dayjs {
    if(date) return dayjs(date);

    return dayjs();
  }
}

export type DayjsType = dayjs.Dayjs;