import { MarketingPromoControll } from "@/helpers/marketingPromoControll";

class MarketingPromoControllFactory {
  private static mktPromo: MarketingPromoControll;


  static make(): MarketingPromoControll {
    if (this.mktPromo) {
      return this.mktPromo;
    }

    return this.createInstance();
  }

  private static createInstance() {
    this.mktPromo = new MarketingPromoControll({
      startDatePromotion: process.env.START_DATE_MARKETING_PROMO || null,
      lastDatePromotion: process.env.LAST_DATE_MARKETING_PROMO || null,
      mktPromoIsEnabledEnv: Boolean(
        Number(process.env.ENABLE_MARKETING_PROMO || '0')
      ),
    });

    return this.mktPromo;
  }
}

export default MarketingPromoControllFactory;