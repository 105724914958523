import { ComponentChild, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Image from '../Image/Image';
import style from './ModalTour.scss';
import React from 'preact/compat';

interface ModalHeaderProps {
  closeModal: (params?: any) => void;
  hasCloseButton?: boolean;
  children: ComponentChild | ComponentChild[];
  twoLineHeader?: boolean;
  showCloseTimeout?: number;
  customCloseImgSrc?: string;
  isAggregateJobFlowOnboarding?: boolean | string | number;
  isLastStep?: boolean;
  skipStep?: () => void;
}

const ModalTourHeader = ({
  closeModal,
  hasCloseButton,
  children,
  twoLineHeader,
  showCloseTimeout = 60000,
  customCloseImgSrc = null,
  isAggregateJobFlowOnboarding,
  isLastStep,
  skipStep,
}: ModalHeaderProps) => {
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timeVar = setTimeout(() => {
      setTimedOut(true);
    }, showCloseTimeout);

    return () => {
      setTimedOut(false);
      clearTimeout(timeVar);
    };
  }, []);

  return (
    <React.Fragment>
      {isAggregateJobFlowOnboarding && !isLastStep && (
        <div data-testid="skip-steps" className={style.SkipButton} onClick={skipStep}>
          Pular
        </div>
      )}
      <div
        className={`${style.Header} ${twoLineHeader && style.TwoLineHeader}`}
      >
        {(hasCloseButton || (timedOut && !isAggregateJobFlowOnboarding)) && (
          <div className={style.CloseButton}>
            <button className='close-tour' type='button' onClick={closeModal}>
              <Image src={customCloseImgSrc || 'images/ic_close.svg'} framed alt='Icone fechar'/>
            </button>
          </div>
        )}
        <div className={style.Title} id='modalTourHeader'>
          <h2>{children}</h2>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalTourHeader;