export const ctaButtonName = () => {
  let ourPlans = 'Ver planos';

  const texts = {
    ourPlans,
  };

  return texts;
};

export const ctaButtonTagText = () => {
  return {
    ...ctaButtonName(),
  };
};

export const closeButtonName = {
  ourPlans: 'Agora não',
  segmentedOffer: 'Agora não',
};

export const title = {
  ourPlans: null,
};
