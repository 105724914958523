interface AdEvents {
  show_ad: string;
  accept_ad: string;
  close_ad: string;
}

const adEvents: AdEvents = {
  show_ad: 'mktadsapp-show-ad',
  accept_ad: 'mktadsapp-accept-ad',
  close_ad: 'mktadsapp-close-ad',
};

export default adEvents;
