import top20StandOutModal from './Top20StandOutModal';
import bigLeapStandOutModal from './BigLeapStandOutModal';
import defaultStandOutModal from './DefaultStandOutModal';

export default {
  top20StandOutModal,
  bigLeapStandOutModal,
  defaultStandOutModal,
  jobViewedTop20: top20StandOutModal,
};
