import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import Image from '@/components/Image/Image';
import Paragraph from '@/components/Paragraph/Paragraph';
import useTargetPlanName from '@/hooks/useTargetPlanName';
import style from './standout.scss';
import { sendOnViewGA4 } from '@/helpers/ga4';
import React from 'preact/compat';
import yieldToMain from '@/helpers/yieldToMain';

interface DefaultStandoutModalProps {
  adId: string;
}

const DefaultStandOutModal = ({
  adId,
}: DefaultStandoutModalProps) => {
  const targetPlanName = useTargetPlanName();

  useEffect(() => {
    const initFunctions = async () => {
      sendOnViewGA4(adId);
      await yieldToMain();
    }

    initFunctions();
  }, []);

  const description = (
    <React.Fragment>
      Candidatos do <b>{targetPlanName}</b> <b>pulam para o topo da lista</b> de
      CVs que o RH desta empresa terá acesso.
    </React.Fragment>
  );

  return (
    <div
      id='apply-ad'
      className={`${style.DefaultStandOutModal}`}
    >
      <div
        className={`${style.DefaultModalContainer}`}
      >
        <Image
          src='images/applies/default-standout.svg'
          alt="Pule para o topo da lista de candidatos"
          framed
        />
        <Paragraph
          textPadding={12}
          data-testid='modal-desc'
        >
          {description}
        </Paragraph>
      </div>
    </div>
  );
};
export default DefaultStandOutModal;
