/* eslint-disable react/no-danger */
import { useEffect, useContext, useState } from 'preact/hooks';
import { Context } from '@/state/store';
import { selectApplyAdByRankPosition } from '@/state/action';
import ModalTour from '@/components/ModalTour/ModalTour';
import useTargetPlanName from '@/hooks/useTargetPlanName';
import Modals from '@/components/Ads/ApplyModals';
import {
  closeButtonName,
  title,
  template,
  getCTAButtonName,
} from './ApplyAdLabels';
import { UserPlan } from '@/types/user';
import { sendMetricEvent } from '@/services/metrics';
import yieldToMain from '@/helpers/yieldToMain';

interface ApplyControllerProps {
  userPlan?: keyof typeof UserPlan;
}

const ApplyController = ({ userPlan }: ApplyControllerProps) => {
  const [state, dispatch] = useContext(Context);
  const [isLoading, setLoading] = useState(false);
  const [jobViewLock, setJobViewLock] = useState(false);
  const [selectedAdId, setSelectedAdId] = useState(null);
  const targetPlanName = useTargetPlanName();
  const applyStandoutList = {
    default: 'defaultStandOutModal',
    top20: 'top20StandOutModal',
    bigLeap: 'bigLeapStandOutModal',
  };

  const jobViewedStandoutList = {
    top20: 'jobViewedTop20',
  };
  const allowedAdIds = [...Object.values(applyStandoutList), ...Object.values(jobViewedStandoutList)]
  const allowedEventsName = ['mktadsapp-apply-made', 'mktadsapp-job-viewed'];
  const shouldRemovePosApplyModals = state.removeModalsVariant === 'c';
  const shouldRemovePreApplyModals = ['b', 'c'].includes(state.removeModalsVariant);

  const handleOnAccept = (adId) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_ACCEPTED', payload: { adId } });
    await yieldToMain();
    setLoading(false);
    await yieldToMain();
  };

  const handleOnClose = (adId) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_CLOSED', payload: { adId } });
    await yieldToMain();
    setLoading(false);
    await yieldToMain();
  };

  const showAdApply = async (payload, handleTime, eventType) => {
    const { jobId } = payload;
    if (
      state.originUrl.match(/apply=true/) ||
      (jobId === state.lastJobIdView && state.jobViewedShow)
    ) {
      sendMetricEvent(
        {
          eventName: eventType,
          adId: 'apply-ads',
          meta: {
            ...payload,
            originUrl: state.originUrl,
          },
        },
        'blockPosApply'
      );
      await yieldToMain();
      return;
    }
    window.requestAnimationFrame(async () => {
      setLoading(true);
    });
    await yieldToMain();
    sendMetricEvent(
      {
        eventName: eventType,
        adId: 'apply-ads',
        meta: {
          ...payload,
          originUrl: state.originUrl,
        },
      },
      'frontSendIARequest'
    );
    await yieldToMain();

    dispatch(
      selectApplyAdByRankPosition({
        jobId,
        apply: payload.applies,
        handleTime,
        standoutList: applyStandoutList,
        eventType,
      })
    );
    await yieldToMain();
  };

  const showAdJobViewed = async (payload, handleTime, eventType) => {
    const canApplyWant = document.querySelector(
      "button[title*='Quero me candidatar']"
    );
    const canApplyFast = document.querySelector(
      "button[title*='Candidatura rápida']"
    );
    const canApplyEasy = document.querySelector(
      "button[title*='Candidatura fácil']"
    );
    await yieldToMain();

    if (
      (!canApplyWant && !canApplyFast && !canApplyEasy) ||
      jobViewLock ||
      state.originUrl.match(/apply=true/)
    ) {
      sendMetricEvent(
        {
          eventName: eventType,
          adId: 'apply-ads',
          meta: {
            ...payload,
            originUrl: state.originUrl,
          },
        },
        'blockPreApply'
      );
      await yieldToMain();
      return;
    }
    const { jobId } = payload;

    sendMetricEvent(
      {
        eventName: eventType,
        adId: 'apply-ads',
        meta: {
          ...payload,
          originUrl: state.originUrl,
        },
      },
      'frontSendIARequest'
    );
    await yieldToMain();
    dispatch(
      selectApplyAdByRankPosition({
        jobId,
        apply: payload.applies,
        handleTime,
        standoutList: jobViewedStandoutList,
        eventType,
      })
    );
    await yieldToMain();

    setTimeout(() => {
      setJobViewLock(false);
    }, 60000);
  };

  const handleMessage = async (message) => {
    const { data } = message;
    const { eventName, payload } = data || {};
    const newPayload = payload || {};
    const handleTime = Date.now();
    if (eventName === 'mktadsapp-apply-made' && !shouldRemovePosApplyModals) {
      showAdApply(newPayload, handleTime, eventName);
      await yieldToMain();
    } else if (eventName === 'mktadsapp-job-viewed' && !shouldRemovePreApplyModals) {
      showAdJobViewed(newPayload, handleTime, eventName);
      await yieldToMain();
    }

    if (['mktadsapp-apply-made', 'mktadsapp-job-viewed'].includes(eventName)) {
      sendMetricEvent(
        {
          eventName,
          adId: 'apply-ads',
          meta: {
            ...payload,
            originUrl: state.originUrl,
          },
        },
        'mktAdsApplyPostMessage'
      );
      await yieldToMain();
    }
  };

  const verifyInternPage = async () => {
    if(shouldRemovePreApplyModals) {
      return;
    }

    const paths = window.location.pathname.split('/');
    let jobId = 0;
    if (
      paths[0] === '' &&
      paths[1] === 'vagas' &&
      paths[2] &&
      paths[2].length >= 1 &&
      paths[3] &&
      paths[3].length >= 1
    ) {
      jobId = parseInt(paths[3], 10);
    } else if (
      paths[1] === 'prod' &&
      paths[2] === 'vagas' &&
      paths[3] === 'jobviewed-onload' &&
      paths[4] === '18651096'
    ) {
      jobId = parseInt(paths[4], 10);
    }
    await yieldToMain();

    if (jobId > 0) {
      const handleTime = Date.now();
      sendMetricEvent(
        {
          eventName: 'mktadsapp-job-viewed',
          adId: 'apply-ads',
          meta: {
            originUrl: state.originUrl,
          },
        },
        'mktAdsPreApplyVerifyInternPage'
      );
      await yieldToMain();
      showAdJobViewed({ jobId }, handleTime, 'mktadsapp-job-viewed');
      await yieldToMain();
    }
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      (eventMessage) => {
        if (eventMessage && allowedEventsName.includes(eventMessage?.data?.eventName || '')) {
          handleMessage(eventMessage);
        }
      },
      false
    );
    verifyInternPage();
  }, []);

  useEffect(() => {
    const checkApplyAdId = async () =>  {
      if (state.applyAdId != null) {
        window.requestAnimationFrame(async() => {
          dispatch({
            type: 'MODAL_AD_OPENED',
            payload: {
              adId: state.applyAdId,
              modalType: 'apply',
            },
          });
          await yieldToMain();
          dispatch({ type: 'MODAL_AD_LOADED', payload: { adId: state.applyAdId } });
          await yieldToMain();
        });
        await yieldToMain();
      }
    }

    checkApplyAdId();
  }, [state.applyAdId]);

  useEffect(() => {
    const checkLoading = async () => {
      if (state.loadedAdId != null && allowedAdIds.includes(state.loadedAdId)) {
        window.requestAnimationFrame(async() => {
          setSelectedAdId(state.loadedAdId);
           await yieldToMain();
           setLoading(false);
           await yieldToMain();
        });
        await yieldToMain();
      }
    }

    checkLoading();
  }, [state.loadedAdId]);

  useEffect(() => {
    const checkLoading = async () => {
      if (state.jobViewedShow) {
        window.requestAnimationFrame(() => {
          setJobViewLock(true);
          setLoading(true);
        })
      } else {
        window.requestAnimationFrame(() => {
          setLoading(false);
        });
      }
      await yieldToMain();
    };

    checkLoading();
  }, [state.jobViewedShow, state.lastJobIdView, state.jobViewedDt]);

  const hasCloseButton = isLoading;
  let layout = null;
  let defaultButton = null;
  let extraButton = null;
  let showNextButton = false;
  let modalTitle = null;
  let showTitle = true;
  let customInfoFooterComponent = null;
  let customTitleComponent = null;
  let closeModal = handleOnClose('loader');

  const setAdComponent = async () => {
    if (selectedAdId) {
      const Modal = Modals[selectedAdId];
      showNextButton = true;
      //@ts-ignore
      modalTitle = template(title[selectedAdId], {
        rankPosition: state.rankPosition,
      });
      showTitle = !!modalTitle;
      closeModal = handleOnClose(selectedAdId);
      const ctaButtonName = getCTAButtonName();

      defaultButton = {
        text: closeButtonName[selectedAdId],
        onClick: handleOnClose(selectedAdId),
        skin: 'link',
        origin: 'ApplyController',
      };
      extraButton = {
        text: template(ctaButtonName[selectedAdId], {
          rankPosition: state.rankPosition,
          targetPlanName,
        }),
        onClick: handleOnAccept(selectedAdId),
        skin: 'secondary',
        origin: 'ApplyController',
        selectedAdId,
      };

      layout = (
        <Modal
          adId={selectedAdId}
          userPlan={userPlan}
        />
      );
      await yieldToMain();
    }
  }
  setAdComponent();

  return (
    (isLoading || selectedAdId) && (
      <ModalTour
        id='apply-modal'
        title={modalTitle}
        closeModal={closeModal}
        showNextButton={showNextButton}
        extraButton={extraButton}
        hasCloseButton={hasCloseButton}
        defaultButton={defaultButton}
        isLoading={isLoading}
        showTitle={showTitle}
        customFooterInfoComponent={customInfoFooterComponent}
        customTitleComponent={customTitleComponent}
      >
        {layout}
      </ModalTour>
    )
  );
};
export default ApplyController;
