import {ComponentChild, h, JSX } from 'preact';
import style from './Paragraph.scss';
interface ParagraphProps extends JSX.HTMLAttributes {
  children: ComponentChild;
  textPadding?: number;
}

const Paragraph = ({ children, textPadding }: ParagraphProps) => {
  const css = textPadding || textPadding === 0 ? { padding: textPadding } : {};

  return (
    <p className={style.JustifiedParagraph} style={css}>
      {children}
    </p>
  );
};

export default Paragraph;