import { h, FunctionComponent } from 'preact';
import { useEffect, useContext } from 'preact/hooks';
import { sendMetricEvent } from '@/services/metrics';
import { Context } from '@/state/store';
import EventsName from '@/constants/EVENTS_NAME';
import style from '@/components/Styles/AdsFontFamily.scss';
import CompatibilityModalsController from '@/controllers/CompatibilityModals/CompatibilityModalsController';

const Checkout: FunctionComponent = () => {
  const [state] = useContext(Context);

  useEffect(() => {
    if (state.loadedAdId) {
      setTimeout(async () => {
        sendMetricEvent(
          {
            eventName: EventsName.show_ad,
            adId: state.loadedAdId,
            startTime: state.handleTime,
            endTime: state.endTime,
            apply: state.apply,
            rankPosition: state.rankPosition,
          },
          'loadedAdId'
        );
      }, 0);
    }
  }, [state.loadedAdId]);

  useEffect(() => {
    if (state.acceptedAdId) {
      setTimeout(async () => {
        sendMetricEvent(
          {
            eventName: EventsName.accept_ad,
            adId: state.acceptedAdId,
            startTime: state.loadTime,
            endTime: state.endTime,
            apply: state.apply,
          },
          'acceptedAdId'
        );
      }, 0);
    }
  }, [state.acceptedAdId]);

  useEffect(() => {
    if (state.closedAdId) {
      setTimeout(async () => {
        sendMetricEvent(
          {
            eventName: EventsName.close_ad,
            adId: state.closedAdId,
            startTime: state.loadTime,
            endTime: state.endTime,
            apply: state.apply,
          },
          'closedAdId'
        );
      }, 0);
    }
  }, [state.closedAdId]);

  return (
    <section>
      <article className={`${style.ModalWrapper}`}>
        <CompatibilityModalsController />
      </article>
    </section>
  );
};

export default Checkout;
