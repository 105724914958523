import { h, Fragment, FunctionComponent } from 'preact';

interface AssetsPrefetcherInterface {
  urlList: string[];
}
const AssetsPrefetcher: FunctionComponent<AssetsPrefetcherInterface> = ({
  urlList,
}) => {
  const links = [];
  for (let i = 0; i < urlList.length; i += 1) {
    if (urlList[i].includes('font')) {
      links.push(
        <link
          key={`prefetch-${i}`}
          crossOrigin=''
          rel='preload'
          href={urlList[i]}
          as='font'
        />
      );
    } else {
      links.push(
        <link key={`prefetch-${i}`} rel='prefetch' href={urlList[i]} as='image' />
      );
    }
  }

  return <Fragment>{links}</Fragment>;
};

export default AssetsPrefetcher;
