import { v4 as uuid } from 'uuid';
import { reportError } from './instana';
import { getServerPath } from './http-utils';

export const logLevels = {
  error: 'error',
  info: 'info',
  warn: 'warn',
  debug: 'debug',
};

const logger = async ({ level, message, data = {} }: { level: string, message: string, data: {} }) =>
  fetch(`${getServerPath()}/api/log/${level}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-transaction-id': uuid(),
    },
    body: JSON.stringify({
      message,
      data,
    }),
  }).catch((error: Error) => {
    reportError(error);
    return null;
  });

export default logger;
